import React, {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom';
import {Router} from 'react-router-dom';
import './index.css'
import {createBrowserHistory} from 'history';
import {Provider} from 'react-redux';
import {store, persistor} from './store/configureStore';
import * as Sentry from '@sentry/react';
import { PersistGate } from 'redux-persist/integration/react'
import CircleSpinner from './components/loading/CircleSpinner';
import {ChakraProvider, extendTheme, ColorModeScript} from '@chakra-ui/react';
import {NotificationProvider} from './provider/NotificationProvider';
import ResponsiveProvider from './provider/ResponsiveProvider';
import NativeAppProvider from './provider/NativeAppProvider';
import { createDefaultWindowValues } from './config/appConfig'
import {Integrations} from '@sentry/tracing';
import appInfo from '../package.json'
import theme from './config/chakraTheme'
import {MajorErrorBoundary} from './components/error/ErrorMessageBoundary';

const App = lazy(() => import('./App'));

// Router history
const history = createBrowserHistory();

// Root element
const rootElement = document.getElementById('root');

createDefaultWindowValues();

// Setup monitoring
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://fc9bb0efe70d4d10bd906b88f1e41485@o409991.ingest.sentry.io/5283484",
    environment: process.env.NODE_ENV,
    release: "halal-munch-react-web-app@" + appInfo.version,
    normalizeDepth: 10,
    integrations: [

      new Integrations.BrowserTracing({
        // Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ]
  });
}


ReactDOM.render(
    <>
      {/*<ColorModeScript initialColorMode={theme.config.initialColorMode} />*/}
      <Provider store={store}>
        <PersistGate loading={<CircleSpinner/>} persistor={persistor}>
          <Router history={history}>
            <ChakraProvider theme={theme} >
              <NativeAppProvider>
                <ResponsiveProvider>
                  <NotificationProvider>
                    <Suspense fallback={<CircleSpinner/>}>
                      <App/>
                    </Suspense>
                  </NotificationProvider>
                </ResponsiveProvider>
              </NativeAppProvider>
            </ChakraProvider>
          </Router>
        </PersistGate>
      </Provider>
    </>
      ,
    rootElement,
);
// registerServiceWorker();
