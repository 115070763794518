// Login
export const ACTION_AUTH_LOGIN_SUCCESSFUL = "ACTION_AUTH_LOGIN_SUCCESSFUL";
export const ACTION_AUTH_LOGIN_FAILURE = "ACTION_AUTH_LOGIN_FAILURE";
export const ACTION_AUTH_LOGIN_REQUEST = "ACTION_AUTH_LOGIN_REQUEST";
export const ACTION_AUTH_LOGIN_SERVER_REQUEST = "ACTION_AUTH_LOGIN_SERVER_REQUEST";

// Logout
export const ACTION_AUTH_LOGOUT_SUCCESSFUL = "ACTION_AUTH_LOGOUT_SUCCESSFUL";
export const ACTION_AUTH_LOGOUT_FAILURE = "ACTION_AUTH_LOGOUT_FAILURE";
export const ACTION_AUTH_LOGOUT_REQUEST = "ACTION_AUTH_LOGOUT_REQUEST";

// User Account
export const ACTION_AUTH_USER_ACCOUNT_REQUEST = "ACTION_AUTH_USER_ACCOUNT_REQUEST";
export const ACTION_AUTH_USER_ACCOUNT_FAILURE = "ACTION_AUTH_USER_ACCOUNT_FAILURE";
export const ACTION_AUTH_USER_ACCOUNT_SUCCESS = "ACTION_AUTH_USER_ACCOUNT_SUCCESS";

