import React, {createContext} from 'react';
import {
  useBreakpointValue,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import {BORDER_DEFAULT_COLOR} from '../constants/styles';
import authService from '../services/authService';

export const ResponsiveContext = createContext({
  isMobile: true,
  bgColor: "",
  fontColor: "",
  borderColor: "",
  colorMode: "",
  remoteConfig: null,
  toggleColorMode: () => null,
})

export default function ResponsiveProvider(props) {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  const bgColor = useColorModeValue("white", "#1A202C")
  const fontColor = useColorModeValue("#1A202C", "#CBD5E0")
  const borderColor = useColorModeValue(BORDER_DEFAULT_COLOR,  "rgba(255,255,255,0.08)")

  const { colorMode, toggleColorMode } = useColorMode()

  return (
      <ResponsiveContext.Provider
          value={{
            isMobile: isMobile,
            bgColor: bgColor,
            fontColor: fontColor,
            borderColor: borderColor,
            colorMode: colorMode,
            remoteConfig: authService.getRemoteConfig(),
            toggleColorMode: toggleColorMode,
          }}
      >
        {props?.children}
      </ResponsiveContext.Provider>
  )
}
