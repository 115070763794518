class Halalness {
    name: string;
    title: string;
    imageUrl: string;

    constructor(name: string, title: string, imageUrl: string) {
        this.name = name;
        this.title = title;
        this.imageUrl = imageUrl;
    }
}

export default Halalness;