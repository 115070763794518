import * as types from './actionTypes';
import unionBy from 'lodash-es/unionBy';

const DEFAULT_ZOOM = 14;
const MAX_ZOOM = 18;
const MIN_ZOOM = 4;

const INITIAL_STATE = {
  // map
  bounds: [],
  lastSearchedBounds: [],
  viewport: {
    latitude: null,
    longitude: null,
    bearing: 0,
    pitch: 0,
    zoom: DEFAULT_ZOOM,
    height: null,
    width: null,
  },

  // restaurants
  isLoading: false,
  restaurants: null,
  recommendedRestaurants: [],
  selectedRestaurant: null,

  // filter
  selectedHalalCategories: [],
  selectedCuisineCategories: [],
  selectedAmenityCategories: [],
  filterSortedBy: "recommended",


  // search
  searchQuery: "",
  searchResults: [],
  searchPage: 0,
  searchEnd: false,
  error: null,
  searchLoading: true,

  tabIndex: 0,
};

const exploreReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    //------------------------------------------
    // FILTERS
    case types.UPDATE_SELECTED_CATEGORIES:
      return {
        ...state,
        selectedHalalCategories: action.payload.halalCategories,
        selectedCuisineCategories: action.payload.cuisineCategories,
        selectedAmenityCategories: action.payload.amenityCategories,
        filterSortedBy: action.payload.filterSortedBy,
      };
    case types.UPDATE_SELECTED_CUISINE_CATEGORIES:
      return {
        ...state,
        selectedCuisineCategories: action.payload,
      };
    case types.UPDATE_SELECTED_AMENITY_CATEGORIES:
      return {
        ...state,
        selectedAmenityCategories: action.payload,
      };
    case types.UPDATE_SELECTED_RESTAURANT:
      return {
        ...state,
        selectedRestaurant: action.payload,
      };
    case types.UPDATE_RESTAURANTS:
      return {
        ...state,
        restaurants: action.payload,
      };

    //------------------------------------------
    // FETCH RESTAURANTS
    case types.FETCH_RESTAURANTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        lastSearchedBounds: state.bounds,
      };
    case types.FETCH_RESTAURANTS_SUCCESS:
      return {
        ...state,
        restaurants: action.payload.restaurants,
        recommendedRestaurants: action.payload.recommendedRestaurants,
        bounds: action.payload.bounds,
        isLoading: false,
      };
    case types.FETCH_RESTAURANTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case types.FETCH_RESTAURANTS_EMPTY:
      return {
        ...state,
        restaurants: [],
        recommendedRestaurants: [],
        isLoading: false,
      };

    //------------------------------------------
    // MAP
    case types.UPDATE_MAP_ZOOM:
      return {
        ...state,
        lastSearchedZoom: state.zoom,
        viewport: {
          ...state.viewport,
          zoom: action.payload > MAX_ZOOM ? MAX_ZOOM : action.payload < MIN_ZOOM ? MIN_ZOOM : action.payload,
        },
      };
    case types.UPDATE_MAP_BOUNDS:
      return {
        ...state,
        bounds: action.payload.bounds,
      };
    case types.UPDATE_MAP_VIEWPORT:
      const zoomUpdate = action.payload?.zoom
      return {
        ...state,
        viewport: {
          ...state.viewport,
          ...action.payload,
          zoom: zoomUpdate > MAX_ZOOM ? MAX_ZOOM : zoomUpdate < MIN_ZOOM ? MIN_ZOOM : zoomUpdate,
        },
      };

    case types.SEARCH_RESTAURANTS_REQUEST:
      return {
        ...state,
        searchResults: [],
        searchLoading: true,
        searchQuery: action.payload?.searchQuery,
        searchPage: 0,
        searchEnd: false,
        selectedHalalCategories: action.payload?.selectedHalalCategories,
        selectedCuisineCategories: action.payload?.selectedCuisineCategories,
        selectedAmenityCategories: action.payload?.selectedAmenityCategories,
      };
    case types.SEARCH_RESTAURANTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        searchLoading: false,
      };
    case types.SEARCH_RESTAURANTS_SUCCESS:
      return {
        ...state,
        searchResults: action.payload,
        searchPage: state.searchPage + 1,
        searchLoading: false,
      };
    case types.SEARCH_RESTAURANTS_NEXT_PAGE_REQUEST:
      return {
        ...state,
        searchLoading: true,
      };
    case types.SEARCH_RESTAURANTS_NEXT_PAGE_FAILURE:
      return {
        ...state,
        error: action.payload,
        searchLoading: false,
      };
    case types.SEARCH_RESTAURANTS_NEXT_PAGE_SUCCESS:
      return {
        ...state,
        error: null,
        searchLoading: false,
        searchPage: state.searchPage + 1,
        searchResults: unionBy(state.searchResults, action.payload, (r) => r.id)
      };
    case types.SEARCH_RESTAURANTS_NEXT_PAGE_END:
      return {
        ...state,
        searchEnd: true,
      };
    case types.SEARCH_RESTAURANTS_CLEAR:
      return {
        ...state,
        error: null,
        searchLoading: false,
        searchPage: 0,
        searchResults: [],
        searchQuery: "",
        selectedHalalCategories: [],
        selectedCuisineCategories: [],
        selectedAmenityCategories: [],
        filterSortedBy: "recommended",
      };
    case types.UPDATE_TAB_INDEX:
      return {
        ...state,
        tabIndex: action?.payload.tabIndex
      };

    default:
      return state;
  }
};

export const getRecommendedRestaurants = state => state.explore.recommendedRestaurants;
export const getExploreRestaurants = state => state.explore.restaurants;
export const getExploreSelectedRestaurant = state => state.explore.selectedRestaurant;

export const getSelectedHalalCategories = state => state.explore.selectedHalalCategories;
export const getSelectedCuisineCategories = state => state.explore.selectedCuisineCategories;
export const getSelectedAmenityCategories = state => state.explore.selectedAmenityCategories;
export const getSearchQuery = state => state.explore.searchQuery;
export const getSearchResults = state => state.explore.searchResults;
export const getFilterSortedBy = state => state.explore.filterSortedBy;
export const getCurrentTabIndex = state => state.explore.tabIndex;


export default exploreReducer;
