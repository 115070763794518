import {
  ACTION_AUTH_LOGIN_FAILURE,
  ACTION_AUTH_LOGIN_REQUEST,
  ACTION_AUTH_LOGIN_SERVER_REQUEST,
  ACTION_AUTH_LOGIN_SUCCESSFUL,
  ACTION_AUTH_LOGOUT_FAILURE,
  ACTION_AUTH_LOGOUT_REQUEST,
  ACTION_AUTH_LOGOUT_SUCCESSFUL,
  ACTION_AUTH_USER_ACCOUNT_FAILURE,
  ACTION_AUTH_USER_ACCOUNT_REQUEST, ACTION_AUTH_USER_ACCOUNT_SUCCESS,
} from './actionTypes';

const INITIAL_STATE = {
  user: null,
  userAccount: null,
  error: null,
  loading: true,
  loadingMessage: "Signing you in..."
};

const authReducer = function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ACTION_AUTH_LOGIN_SERVER_REQUEST:
      return {
        ...state,
        loading: true,
        loadingMessage: "Authenticating you...",
        error: null,
      };
    case ACTION_AUTH_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        loadingMessage: "Signing you in...",
        error: null,
      };
    case ACTION_AUTH_LOGIN_SUCCESSFUL:
      return {
        ...state,
        user: action.user,
        loading: false,
        loadingMessage: null,
        error: null,
      };
    case ACTION_AUTH_LOGIN_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case ACTION_AUTH_LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
        loadingMessage: "Signing you out...",
      };
    case ACTION_AUTH_LOGOUT_SUCCESSFUL:
      return {
        ...state,
        user: null,
        error: null,
        loading: false,
        loadingMessage: null,
      };
    case ACTION_AUTH_LOGOUT_FAILURE:
      return {
        ...state,
        user: null,
        error: action.error,
        loading: false,
        loadingMessage: null,
      };
    case ACTION_AUTH_USER_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        loadingMessage: "Fetching user details..."
      };
    case ACTION_AUTH_USER_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        loadingMessage: null,
      };
    case ACTION_AUTH_USER_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingMessage: null,
        userAccount: action.payload,
      };
    default:
      return state;
  }
};

export const isAuthenticatedWithServer = (state) => state.auth.user != null && state.auth.userAccount != null;
export const isAuthenticatedWithFirebase = isAuthenticatedWithServer
export const getAuthUser = (state) => state.auth.user;
export const getAuthUserAccount = (state) => state.auth.userAccount;
export const isAuthUserAdmin = (state) => state.auth.userAccount?.role === "ADMIN_USER";
export const isAnonymousUser = (state) => state.auth.user != null || state.auth.user?.isAnonymous;
export const isAuthLoading = (state) => state.auth.loading || window.NATIVE_authLoading;
export const getAuthError = (state) => state.auth.error;

export default authReducer;
