module.exports = {

// Colors

  BORDER_DEFAULT_COLOR: 'rgba(0,0,0,0.08)',

  COLOR_VIEW_BACKGROUND_DEFAULT: '#f9fafa', //#fafafa
  COLOR_BACKGROUND_PRIMARY: '#ffffff', //#fafafa

  COLOR_PRIMARY: '#E74C3C',
  COLOR_PRIMARY_LIGHT: '#ff7f67',
  COLOR_PRIMARY_DARK: '#ae0c13',

  COLOR_SECONDARY: '#243747',
  COLOR_SECONDARY_DARK: '#456990',

//   COLOR_SUCCESS : "#5BE178",
  COLOR_SUCCESS: '#11ae4a',

  COLOR_DANGER: '#ff4242',

  COLOR_INFO: '#455a64',
  COLOR_INFO_LIGHT: '#718792',
  COLOR_INFO_DARK: '#1c313a',

  COLOR_DISABLED: '#C1C1C1',
  COLOR_DARK: '#727272',

  COLOR_TEXT_DEFAULT: '#1c1c1c',
  COLOR_TEXT_INPUT_DEFAULT: '#5a5a5a',
  COLOR_TEXT_INPUT_BACKGROUND_DEFAULT: '#eeeeee',
  COLOR_TEXT_INPUT_BACKGROUND_DISABLED: '#c1c1c1',
  COLOR_TEXT_MUTED: 'rgb(117, 117, 117)',

// Dimensions
  NAVBAR_HEIGHT: '44px',
  TITLE: '44px',

// Fonts
  FONT_FAMILY: '"Signika", -apple-system, system-ui, sans-serif !important,',
  FONT_LIGHT: 300,
// $options-height: 50px,
// $full-screen-height: 100%,`
// $full-app-height: calc(#{$full-screen-height} - #{$navbar-height}),
// $main-height: calc(#{$full-app-height} - #{$options-height}),

// Responsive
  WIDTH_DEVICES: {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
  },

  zIndexes: {
    DOCK_NAV_LAYER: 200,
    POP_UP_FOR_SCREEN_LAYER: 300,
    POP_UP_LAYER: 400,
    NOTIFICATION_LAYER: 1000,
  },

  colors: {
    PRIMARY: '#E74C3C',
    SECONDARY: '#243747',
    SECONDARY_ALT: '#456990',
    ACCENT_HIGH: '#A5F8D3',
    ACCENT_MID: '#FFE75D',
    ACCENT_LOW: '#FF8591',
    TERTIARY: '#FFFFFF',
    DISABLED: '#DDDDDD',
    DISABLED_TEXT: '#243747',
    WHITE: '#FFFFFF',

    SUCCESS: '#11ae4a',
    DANGER: '#ff4242',
    INFO: '#4198be',
    WARNING: '#eb8615',

    AMENITY_COLOR: '#6495ED',

    BORDER_DEFAULT: 'rgba(0,0,0,0.08)',

    BACKGROUND_PAGE_DEFAULT: '#f9fafa',
    BACKGROUND_DEFAULT: '#ffffff',

    BUTTON_LINK_TEXT_DARK: '#77797B',
    BUTTON_LINK_TEXT_LIGHT: '#FFFFFF',

    INPUT_TEXT: '#5a5a5a',
    INPUT_HELP_TEXT: '#818181',
    INPUT_BACKGROUND: '#ffffff',
    INPUT_BORDER: '#AEB0B2',

    TEXT_DEFAULT: '#1c1c1c',
  },

  RESPONSIVE_WIDTHS: {
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px"
  },

};
