import * as types from './actionTypes';
import Logger from '../../utils/Logger';
import Halalness from '../../models/Halalness';
import Cuisine from '../../models/Cuisine';
import Amenity from '../../models/Amenity';
import isEmpty from 'lodash-es/isEmpty';

type StateType = {
  halalCategories: Array<Halalness>,
  cuisineCategories: Array<Cuisine>,
  amenityCategories: Array<Amenity>,
  error: string,
  retryCount: number
}

const INITIAL_STATE: StateType = {
  halalCategories: [],
  cuisineCategories: [],
  amenityCategories: [],
  error: null,
  retryCount: 0
};

const staticReducer = function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.FETCH_CATEGORIES_SUCCESS:
      return {
        halalCategories: action.payload.halalnessCategories,
        cuisineCategories: action.payload.cuisineCategories,
        amenityCategories: action.payload.amenityCategories,
        error: null
      };
    case types.FETCH_CATEGORIES_FAILURE:
      return {
        halalCategories: [],
        cuisineCategories: [],
        amenityCategories: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export const isCategoriesError = (state) => state.static.error ||
    isEmpty(state.static.halalCategories) ||
    isEmpty(state.static.cuisineCategories)||
    isEmpty(state.static.amenityCategories)

export default staticReducer;
