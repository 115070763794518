import React, {Fragment} from 'react';
import {
  COLOR_DANGER
} from '../../constants/styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import {Message} from './InfoMessage';
import {Text} from '@chakra-ui/react';

type Props = {
  message: string
}

function ErrorMessage(props : Props) {

  return (
    <Message
        icon={faExclamationTriangle}
        iconColor={COLOR_DANGER}
        message={props.message}
    />
  )
}


export default ErrorMessage;
