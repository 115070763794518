import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import Logger from '../utils/Logger';
import join from 'lodash-es/join'
import ServiceException from "../exceptions/ServiceException";
import {
  Restaurant, BasketValidationRequest,
  BasketRequestItem,
  BasketValidationResponse, BasketCheckoutRequest, Order, UserAccount,
  RestaurantList, MenuGroup, CheckoutResponse,
} from '../models/models';
import PostRestaurantRequest from '../models/PostRestaurantRequest';
import PostRestaurantResponse from '../models/PostRestaurantResponse';
import RestaurantReview from '../models/RestaurantReview';
import ServiceHandler from './ServiceHandler';
import GetRestaurantsMapResponse from '../models/GetRestaurantsMapResponse';
import PostRestaurantSignUpRequest from '../models/PostRestaurantSignUpRequest';
import AutocompleteResponse, {AutocompletePrediction} from '../models/AutocompleteResponse';
import Halalness from '../models/Halalness';
import Cuisine from '../models/Cuisine';
import {HALALMUNCH_SERVICE_API_URL} from '../config/appConfig';
import Point from '../models/Point';
import dayjs from 'dayjs';
import authService from './authService';
import {isEmpty} from 'lodash-es';
import {UpdateRestaurantRequest} from '../models/RequestResponse';

export class ApiResponse<T> {
  status: number;
  message: string;
  error:string;
  result: T;
  timestamp: Date;

  constructor(
      status: number, message: string, error: string, result: T,
      timestamp: Date) {
    this.status = status;
    this.message = message;
    this.error = error;
    this.result = result;
    this.timestamp = timestamp;
  }
}

export class RestaurantFeaturesResponse {
  mappings: Array<string>;
  features: Array<{
    type: string,
    title: string,
    restaurants: Array<string>
  }>;

  constructor(
      mappings: Array<string>,
      features: Array<{ type: string, title: string, restaurants: Array<string> }>) {
    this.mappings = mappings;
    this.features = features;
  }
}

class ApiService {
  logger = new Logger(ApiService.name);

  constructor() {
    this.axiosApiInstance = axios.create({
      baseURL: HALALMUNCH_SERVICE_API_URL,
      timeout: 15000,
    })

    // Request interceptor for API calls
    this.axiosApiInstance.interceptors.request.use(
        async config => {
          this.logger.debug("Axios request intercepted")
          const idToken = await authService.doGetRefreshToken();
          config.headers = {
            'Authorization': `Bearer ${idToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
          return config;
        },
        error => {
          this.logger.info("Axios request failed", error)
          return Promise.reject(error)
        });

    // // Response interceptor for API calls
    // this.axiosApiInstance.interceptors.response.use((response) => {
    //   return response
    // }, async (error) => {
    //   this.logger.info("Axios response failed", error)
    //   const originalRequest = error.config;
    //   if (error.response.status === 403 && !originalRequest._retry) {
    //     originalRequest._retry = true;
    //     const user = await authService.getUser()
    //     const idToken = await authService.doGetRefreshToken(true);
    //     this.axiosApiInstance.defaults.headers.common['Authorization'] = 'Bearer ' + idToken;
    //     return this.axiosApiInstance(originalRequest);
    //   }
    //   return Promise.reject(error);
    // });
  }

  saveCache(key, data) {
    const cacheData = {
      data: data,
      lastUpdated: dayjs().toISOString()
    }
    window.localStorage.setItem(`halalmunch:${key}`, JSON.stringify(cacheData));
    return cacheData;
  }

  loadCacheData(key) {
    return this.loadCache(key).data
  }

  loadCache(key) {
    const dataStr = window.localStorage.getItem(`halalmunch:${key}`);
    const jsonData = JSON.parse(dataStr) || {};
    const {data = null, lastUpdated = null} = {...jsonData};
    this.logger.info("cache loaded for key:", key, "lastUpdated:" + lastUpdated)
    return  {data, lastUpdated};
  }

  async getCached(url, config, forceCacheBust) : AxiosResponse {
    this.logger.info("Get for cached api " + url)
    const cacheKey = String(url)+''+JSON.stringify(config);
    this.logger.info("cache key: " + cacheKey)
    const {data, lastUpdated} = this.loadCache(cacheKey)
    // this.logger.info("Today: " + dayjs().subtract(1, "day"))
    if (forceCacheBust || data == null ||  isEmpty(data) || dayjs().isAfter(dayjs(lastUpdated).add(1, "day"))) {
      this.logger.info("data is null or cache expired, data:" + data + " lastUpdated:" + lastUpdated)
      const response = await this.axiosApiInstance.get(url, config)
      if (response.status === 200 && response.data) {
        this.saveCache(cacheKey, response.data)
      }
      return response
    } else {
      this.logger.info("cache hit data:" + data)
      return {
        data: data,
        status: 200,
        statusText: "200",
      }
    }
  }

  // ################################################
  // Restaurant Queries
  // ################################################

  async fetchRestaurants(bounds: Array<number>,
                         latitude: number,
                         longitude: number,
                         halalCategories: Array<any>,
                         cuisineCategories: Array<string>,
                         amenityCategories: Array<string>) : GetRestaurantsMapResponse {
    this.logger.info("Fetching restaurants", bounds, latitude, longitude, halalCategories, cuisineCategories, amenityCategories);
    let response = await this.getCached(`/restaurants/map`, {
        params: {
          bbox: join(bounds, ","),
          lat: latitude,
          lng: longitude,
          halalnesses: join(halalCategories, ","),
          cuisines: join(cuisineCategories, ","),
          amenities: join(amenityCategories, ","),
          format: "json"
        }
      }).catch(ServiceHandler.handleServiceError);

    this.logger.info(response);
    if (response.status === 200) {
      return Object.assign(new GetRestaurantsMapResponse(), response.data);
    } else {
      throw new ServiceException("Failed to fetch restaurants");
    }
  }

  async fetchRestaurantDetailsByIdWithLatLon(id, latitude, longitude) {
    this.logger.info(`Fetching restaurants details using id:${id}, lat:${latitude}, lon:${longitude}`);

    let response;
    try {
      response = await this.getCached(`/restaurants/map/${id}`, {
        params: {lat:latitude, lng:longitude}
      });
    } catch (e) {
      this.logger.error(e);
      ServiceHandler.handleServiceError(e);
    }

    this.logger.info(response);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new ServiceException("Failed to fetch restaurants");
    }
  }

  async fetchRestaurantDetailsById(id : string, skipCache: boolean = false) : Restaurant {
    const errorMessage = "Sorry we are unable to retrieve restaurant details at this moment, please try again later";
    this.logger.info(`Fetching restaurants details using id:${id}`);

    let response;
    if (skipCache) {
      response = await this.axiosApiInstance.get(`/restaurants/${id}`, {})
          .catch(e => ServiceHandler.handleServiceError(e, errorMessage))
    } else {
      response = await this.getCached(`/restaurants/${id}`, {})
          .catch(e => ServiceHandler.handleServiceError(e, errorMessage))
    }

    const data = ServiceHandler.handleAxiosResponse(response, errorMessage)
    return Object.assign(new Restaurant(), data);
  }

  async fetchRestaurantByQueryAndPage(query: string, page: number,
      halalCategories: Array<Halalness>, cuisineCategories: Array<Cuisine>,
      amenityCategories: Array<Amenity>, sortBy: string,
      lat: number,
      lng: number,
      ): Array<Restaurant> {
    const errorMessage = "Sorry, we are unable to retrieve restaurants at this moment, please try again later";
    this.logger.info("Fetching restaurants by query " + query + ", page " + page);

    let response = await this.axiosApiInstance.get(`/restaurants`, {
        params: {
          search:query,
          page:page,
          halalCategories:join(halalCategories, ","),
          amenityCategories: join(amenityCategories, ","),
          cuisineCategories: join(cuisineCategories, ","),
          sort: sortBy,
          lat: lat,
          lng: lng
        }
      }).catch(e => ServiceHandler.handleServiceError(e, errorMessage));
    const data = ServiceHandler.handleAxiosResponse(response, errorMessage)
    return data.map(r => Object.assign(new Restaurant(), r));
  }

  async fetchRestaurantsRecentlyAdded(latitude: number, longitude: number): Array<Restaurant> {
    const errorMessage = "Sorry, we are unable to retrieve restaurants at this moment, please try again later";
    this.logger.info("Fetching recently added restaurants");
    let response = await this.getCached(`/restaurants/recent`, {
        params: {lat: latitude, lng: longitude}
      }).catch(e => ServiceHandler.handleServiceError(e, errorMessage));
    const data = ServiceHandler.handleAxiosResponse(response, errorMessage)
    return data.map(r => Object.assign(new Restaurant(), r));
  }

  async fetchRestaurantsNearby(latitude: number, longitude: number): Array<Restaurant> {
    const errorMessage = "Sorry, we are unable to retrieve restaurants at this moment, please try again later";
    this.logger.info("Fetching nearby added restaurants");
    let response = await this.getCached(`/restaurants/nearby`, {
        params: {lat: latitude, lng: longitude}
      }).catch(e => ServiceHandler.handleServiceError(e, errorMessage));
      const data = ServiceHandler.handleAxiosResponse(response, errorMessage)
      return data.map(r => Object.assign(new Restaurant(), r));
  }

  async fetchRestaurantsNearbyAll(latitude: number, longitude: number, force: boolean=false): Promise<RestaurantFeaturesResponse> {
    const errorMessage = "Sorry, we are unable to retrieve restaurants at this moment, please try again later";
    this.logger.info("Fetching nearby added restaurants");
    let response = await this.getCached(`/restaurants/nearby/all`, {
        params: {lat: latitude, lng: longitude}
      }, force).catch(e => ServiceHandler.handleServiceError(e, errorMessage));
      const data = ServiceHandler.handleAxiosResponse(response, errorMessage)
      return data
  }

  async fetchRestaurantsNearbyTopRated(latitude: number, longitude: number): Array<Restaurant> {
    const errorMessage = "Sorry, we are unable to retrieve restaurants at this moment, please try again later";
    this.logger.info("Fetching nearby added restaurants");
    let response = await this.getCached(`/restaurants/nearby/top-rated`, {
        params: {lat: latitude, lng: longitude}
      }).catch(e => ServiceHandler.handleServiceError(e, errorMessage));
    const data = ServiceHandler.handleAxiosResponse(response, errorMessage)
    return data.map(r => Object.assign(new Restaurant(), r));
  }

  async fetchRestaurantsNearbyRecommended(latitude: number, longitude: number): Array<Restaurant> {
    const errorMessage = "Sorry, we are unable to retrieve restaurants at this moment, please try again later";
    this.logger.info("Fetching nearby recommended restaurants");
    let response = await this.getCached(`/restaurants/nearby/recommended`, {
        params: {lat: latitude, lng: longitude}
      }).catch(e => ServiceHandler.handleServiceError(e, errorMessage));
    const data = ServiceHandler.handleAxiosResponse(response, errorMessage)
    return data.map(r => Object.assign(new Restaurant(), r));
  }

  async fetchRestaurantsByArea(area: string, breakCache: boolean = false): Promise<RestaurantFeaturesResponse> {
    const errorMessage = "Sorry, we are unable to retrieve restaurants at this moment, please try again later";
    this.logger.info("Fetching nearby recommended restaurants");
    let response = await this.getCached(`/restaurants/neighbourhood/${area}`, {
      }, breakCache).catch(e => ServiceHandler.handleServiceError(e, errorMessage));
    const data = ServiceHandler.handleAxiosResponse(response, errorMessage)
    return Object.assign(new RestaurantFeaturesResponse(), data);
  }

  // ################################################
  // Restaurant Updates
  // ################################################

  async postRestaurant(request: PostRestaurantRequest, confirm: boolean = false, sessionToken?: string): PostRestaurantResponse {
    this.logger.info("submitting restaurant");
    const errorMessage = "Sorry, we are unable to submit your request at this moment, please try again later";
    let response = await this.axiosApiInstance.post(`/restaurant`, request, {
        params: {sessionToken: sessionToken, confirm: confirm}
      }).catch(e => ServiceHandler.handleServiceError(e, errorMessage));
    const data = ServiceHandler.handleAxiosResponse(response, errorMessage)
    return Object.assign(new PostRestaurantResponse(), data);
  }

  async updateRestaurant(restaurantId:string, updateData: UpdateRestaurantRequest, confirm: boolean = false, sessionToken?: string): PostRestaurantResponse {
    this.logger.info("submitting restaurant");
    const errorMessage = "Sorry, we are unable to submit your request at this moment, please try again later";
    let response = await this.axiosApiInstance.put(`/restaurant/${restaurantId}`, updateData, {
        params: {sessionToken: sessionToken, confirm: confirm}
      }).catch(e => ServiceHandler.handleServiceError(e, errorMessage));
    const data = ServiceHandler.handleAxiosResponse(response, errorMessage)
    return Object.assign(new PostRestaurantResponse(), data);
  }

  async updateAddress(autocompletePrediction: AutocompletePrediction, sessionToken?: string): Restaurant {
    this.logger.info("Updating restaurant address", autocompletePrediction);

    let response = await this.axiosApiInstance.post(`/restaurants/address`, {
      autocompletePrediction: autocompletePrediction
    }, {
      params: {sessionToken: sessionToken}
    }).catch(ServiceHandler.handleServiceError)

    return Object.assign(new Restaurant(), response.data);
  }

  updateRestaurantAnalyticsViewEvent(restaurantId:string): Restaurant {
    return this._updateRestaurantAnalyticsEvent(restaurantId, 'restaurant_viewed')
  }

  _updateRestaurantAnalyticsEvent(restaurantId:string, event_type: string): Restaurant {
    this.logger.info("Updating restaurant analytics event", event_type);

    this.axiosApiInstance.post(`/analytics/restaurants/${restaurantId}`, {
      event_type: event_type
    },).catch(() => null);

  }

  // ################################################
  // Restaurant Reviews
  // ################################################

  async getReviews(restaurantId : string) : Array<RestaurantReview> {
    const errorMessage = "Sorry, we are unable to retrieve restaurant reviews at this moment, please try again later";
    this.logger.info("Fetching reviews for restaurant id", restaurantId);
    let response = await this.getCached(`/restaurants/${restaurantId}/reviews`)
        .catch(e => ServiceHandler.handleServiceError(e, errorMessage));
    const data = ServiceHandler.handleAxiosResponse(response, errorMessage)
    return data.map(r => Object.assign(new RestaurantReview(), r));
  }

  async getUserReviewForRestaurant(restaurantId : string) : RestaurantReview {
    const errorMessage = "Sorry, we are unable to retrieve restaurant reviews at this moment, please try again later";
    this.logger.info("Fetching reviews for restaurant id", restaurantId);
    let response = await this.axiosApiInstance.get(`/restaurants/${restaurantId}/reviews/user`)
        .catch(e => ServiceHandler.handleServiceError(e, errorMessage));
    const data = ServiceHandler.handleAxiosResponse(response, errorMessage);
    return Object.assign(new RestaurantReview(), data);
  }

  async postReview(rating: number, comments: string, restaurantId: string): RestaurantReview {
    const errorMessage = "Failed to submit restaurant review";
    this.logger.info("Posting review for restaurant", restaurantId);
    let response = await this.axiosApiInstance.post(`/restaurants/${restaurantId}/reviews`, {
        rating: rating, comments: comments
      }).catch(e => ServiceHandler.handleServiceError(e, errorMessage))

    const data = ServiceHandler.handleAxiosResponse(response, errorMessage);
    return Object.assign(new RestaurantReview(), data);
  }

  // ################################################
  // Restaurant Home Cooks
  // ################################################

  async getRestaurantTypes() : Array<String> {
    this.logger.info("Fetching restaurant types");
    let response = await this.getCached(`/restaurant/types`)
        .catch(ServiceHandler.handleServiceError);

    return ServiceHandler.handleAxiosResponse(response, "Failed to get restaurant types");
  }

  async fetchRestaurantSellersNearby(latitude: number, longitude: number): Array<Restaurant> {
    const errorMessage = "Sorry, we are unable to retrieve sellers at this moment, please try again later";
    let response = await this.getCached(`/restaurants/market/nearby`, {
      params: {lat: latitude, lng: longitude}
    }).catch(e => ServiceHandler.handleServiceError(e, errorMessage));
    const data = ServiceHandler.handleAxiosResponse(response, errorMessage)
    return data?.map(r => Object.assign(new Restaurant(), r));
  }

  async fetchRestaurantSellersNearbyAll(latitude: number, longitude: number): Promise<RestaurantFeaturesResponse> {
    const errorMessage = "Sorry, we are unable to retrieve sellers at this moment, please try again later";
    let response = await this.getCached(`/restaurants/market/all`, {
      params: {lat: latitude, lng: longitude}
    }).catch(e => ServiceHandler.handleServiceError(e, errorMessage));
    const data = ServiceHandler.handleAxiosResponse(response, errorMessage)
    return Object.assign(new RestaurantFeaturesResponse(), data);
  }

  async fetchRestaurantSellersNearbyTopRated(latitude: number, longitude: number): Array<Restaurant> {
    const errorMessage = "Sorry, we are unable to retrieve sellers at this moment, please try again later";
    let response = await this.getCached(`/restaurants/market/nearby`, {
      params: {lat: latitude, lng: longitude}
    }).catch(e => ServiceHandler.handleServiceError(e, errorMessage));
    const data = ServiceHandler.handleAxiosResponse(response, errorMessage)
    return data?.map(r => Object.assign(new Restaurant(), r));
  }

  async fetchRestaurantMenu(id: number): Array<MenuGroup> {
    const errorMessage = "Sorry, we are unable to retrieve restaurant menu at this moment";
    let response = await this.axiosApiInstance.get(`/restaurants/${id}/menu`)
        .catch(e => ServiceHandler.handleServiceError(e, errorMessage));
    const data = ServiceHandler.handleAxiosResponse(response, errorMessage)
    return data?.map(d => Object.assign(new MenuGroup(), d));
  }

  // ################################################
  // Orders
  // ################################################

  async validateBasket(items: Array<BasketRequestItem>): BasketValidationResponse {
    const errorMessage = "Sorry, we are unable to checkout at the moment";
    let response = await this.axiosApiInstance.post(`/orders/basket`,
        {items: items}
        )
        .catch(e => ServiceHandler.handleServiceError(e, errorMessage));
    const data = ServiceHandler.handleAxiosResponse(response, errorMessage)
    return Object.assign(new BasketValidationResponse(), data);
  }

  async checkoutOrder(basketRequest: BasketCheckoutRequest): Promise<ApiResponse<CheckoutResponse>> {
    const errorMessage = "Sorry, we are unable to checkout at the moment";
    let response = await this.axiosApiInstance.post(`/orders/checkout`,
        basketRequest
        )
        .catch(e => ServiceHandler.handleServiceError(e, errorMessage));
    return ServiceHandler.handleAxiosResponse(response, errorMessage, errorMessage, false)
  }

  async clearPendingOrders(): Promise<ApiResponse> {
    const errorMessage = "Sorry, we are unable to checkout at the moment";
    let response = await this.axiosApiInstance.post(`/orders/remove-pending`,)
        .catch(e => ServiceHandler.handleServiceError(e, errorMessage));
    return ServiceHandler.handleAxiosResponse(response, errorMessage, errorMessage, false)
  }

  async getOrdersForUser(status?: string): Promise<Array<Order>> {
    const errorMessage = "Sorry, we are unable to checkout at the moment";
    let response = await this.axiosApiInstance.get(`/orders`, {
      params: {
        status: status
      }
    })
        .catch(e => ServiceHandler.handleServiceError(e, errorMessage));
    const data = ServiceHandler.handleAxiosResponse(response, errorMessage, errorMessage, true)
    return data.map(d => Object.assign(new Order(), d));
  }

  async getOrderByPaymentIntent(payment_intent_id: string): Promise<Order> {
    const errorMessage = "Sorry, we are unable to fetch your order at the moment";
    let response = await this.axiosApiInstance.get(`/orders/by-payment-id/${payment_intent_id}`)
        .catch(e => ServiceHandler.handleServiceError(e, errorMessage));
    const data = ServiceHandler.handleAxiosResponse(response, errorMessage, errorMessage, true)
    return Object.assign(new Order(), data);
  }

  async getOrderById(orderId: string): Promise<Order> {
    const errorMessage = "Sorry, we are unable to fetch your order at the moment";
    let response = await this.axiosApiInstance.get(`/orders/${orderId}`)
        .catch(e => ServiceHandler.handleServiceError(e, errorMessage));
    const data = ServiceHandler.handleAxiosResponse(response, errorMessage, errorMessage, true)
    return Object.assign(new Order(), data);
  }

  // ################################################
  // Categories
  // ################################################

  async fetchHalalCategories() {
    this.logger.info("Fetching halal categories");
    let response;
    try {
      response = await this.getCached(`/halalness`);
    } catch (e) {
      ServiceHandler.handleServiceError(e);
    }

    this.logger.info(response);
    if (response.status === 200) {
      return response.data.map(r => Object.assign(new Halalness(), r));
    } else {
      throw new ServiceException("Failed to fetch halal categories");
    }
  }

  async fetchAllCategories() : {cuisineCategories: Array<Cuisine>, halalnessCategories: Array<Halalness>, amenityCategories: Array<Amenity>} {
    this.logger.info("Fetching all categories");
    let response;
    try {
      response = await this.getCached('/categories');
    } catch (e) {
      ServiceHandler.handleServiceError(e);
    }

    this.logger.info(response);
    if (response.status === 200) {
      return Object.assign({
        cuisineCategories: [],
        halalnessCategories: [],
        amenityCategories: [],
      }, response.data);
    } else {
      throw new ServiceException("Failed to fetch all categories");
    }
  }

  // ################################################
  // Address Autocomplete
  // ################################################

  async fetchAddress(addressQuery, sessionToken, lat, lng) : Promise<AutocompleteResponse> {
    const errorMessage = "Sorry we could not find any addresses at this moment, please try again later";
    let response = await this.axiosApiInstance.get(`/places/addresses`,
        {
          params: {query: addressQuery, sessionToken: sessionToken, lat:lat, lng:lng},
        })
        .catch(e => ServiceHandler.handleServiceError(e, errorMessage))

    this.logger.info(response);
    const data = ServiceHandler.handleAxiosResponse(response, errorMessage);
    return Object.assign(new AutocompleteResponse(), data);
  }

  async fetchAddressPostcode(addressQuery, sessionToken, lat, lng) : Promise<AutocompleteResponse> {
    const errorMessage = "Sorry we could not find any addresses at this moment, please try again later";
    let response = await this.axiosApiInstance.get(`/places/addresses/postcode`,
        {
          params: {query: addressQuery, sessionToken: sessionToken, lat:lat, lng:lng},
        })
        .catch(e => ServiceHandler.handleServiceError(e, errorMessage))

    this.logger.info(response);
    const data = ServiceHandler.handleAxiosResponse(response, errorMessage);
    return Object.assign(new AutocompleteResponse(), data);
  }

  async fetchRestaurantsAutocomplete(addressQuery, sessionToken) : AutocompleteResponse {
    const errorMessage = "Sorry we could not find any restaurants at this moment, please try again later";
    let response = await this.axiosApiInstance.get(`/places/restaurants/search`,
        {
          params: {query: addressQuery, sessionToken: sessionToken},
        })
        .catch(e => ServiceHandler.handleServiceError(e, errorMessage))

    this.logger.info(response);
    const data = ServiceHandler.handleAxiosResponse(response, errorMessage);
    return Object.assign(new AutocompleteResponse(), data);
  }

  async fetchAddressLocation(address : string) : Point {
    const errorMessage = "Sorry we could not find your address location at this moment, please try again later";
    let response = await this.getCached('/places/location', {
      params: {
        address: address
      }
    })
        .catch(e => ServiceHandler.handleServiceError(e, errorMessage))

    this.logger.info(response);
    const data = ServiceHandler.handleAxiosResponse(response, errorMessage);
    return Object.assign(new Point(), data);
  }


  async autocomplete(query, session_token, lat, lng) {
    const errorMessage = "Sorry we could not find your address location at this moment, please try again later";
    let response = await this.axiosApiInstance.get(
          `/restaurants/autocomplete/${query}`, {
            params: {session_token: session_token, point: `${lat},${lng}`}
          })
        .catch(e => ServiceHandler.handleServiceError(e, errorMessage))

    this.logger.info(response);
    return ServiceHandler.handleAxiosResponse(response, errorMessage);

  }

  // ################################################
  // User Api
  // ################################################

  async getUserAccount() : UserAccount {
    const response = await this.axiosApiInstance.get(`/user`)
        .catch(ServiceHandler.handleServiceError);


    const data = ServiceHandler.handleAxiosResponse(response);
    return Object.assign(new UserAccount(), data)
  }

  async updateUserAccount(userAccount : UserAccount) : UserAccount {
    const response = await this.axiosApiInstance.put(`/user`, userAccount)
        .catch(ServiceHandler.handleServiceError);

    const data = ServiceHandler.handleAxiosResponse(response);
    return Object.assign(new UserAccount(), data)
  }

  async getAllUserReviews() : Array<RestaurantReview> {
    this.logger.info("Fetching reviews for user");
    let response = await this.axiosApiInstance.get(`/user/reviews`)
        .catch(ServiceHandler.handleServiceError);


    const data = ServiceHandler.handleAxiosResponse(response, "Failed to fetch your reviews");
    return data.map((d) => Object.assign(new RestaurantReview(), d))
  }


  // ################################################
  // User Api
  // ################################################

  async getRestaurantListsForCurrentUser() : Array<RestaurantList> {
    this.logger.info("Fetching reviews for user restaurant lists");
    let response = await this.axiosApiInstance.get(`/restaurant-lists/current-user`)
        .catch(ServiceHandler.handleServiceError);


    const data = ServiceHandler.handleAxiosResponse(response, "Failed to fetch your restaurant lists");
    return data.map((d) => Object.assign(new RestaurantList(), d))
  }

  async getRestaurantListByUsernameAndListnameSlug(username: string, listname: string) : RestaurantList {
    this.logger.info("Fetching reviews for user restaurant lists");
    let response = await this.axiosApiInstance.get(`/restaurant-lists/public/${username}/${listname}`, {
    })
        .catch(ServiceHandler.handleServiceError);

    const data = ServiceHandler.handleAxiosResponse(response, "Failed to fetch your restaurant lists");
    return Object.assign(new RestaurantList(), data);
  }

  async postRestaurantList(name: string, restaurants: Array<string>) : RestaurantList {
    const response = await this.axiosApiInstance.post(`/restaurant-lists`, {
      name: name, restaurants: restaurants
    })
        .catch(ServiceHandler.handleServiceError);

    const data = ServiceHandler.handleAxiosResponse(response);
    return Object.assign(new RestaurantList(), data)
  }

  async putRestaurantList(id: string, listname: string, restaurants: Array<string>) : RestaurantList {
    const response = await this.axiosApiInstance.put(`/restaurant-lists/${id}`, {
      name: listname, restaurants: restaurants
    })
        .catch(ServiceHandler.handleServiceError);

    const data = ServiceHandler.handleAxiosResponse(response);
    return Object.assign(new RestaurantList(), data)
  }

  async deleteRestaurantList(username: string, listname: string) : null {
    const response = await this.axiosApiInstance.delete(`/restaurant-lists/${username}/${listname}`)
        .catch(ServiceHandler.handleServiceError);

    return ServiceHandler.handleAxiosResponse(response);
  }

  // ################################################
  // User Api
  // ################################################

  async deleteAccount(details: any) : RestaurantList {
    const response = await this.axiosApiInstance.post(`/user/delete-account`, {
      details
    })
        .catch(ServiceHandler.handleServiceError);

    const data = ServiceHandler.handleAxiosResponse(response);
    return Object.assign(new RestaurantList(), data)
  }

  async sendEnquiry(email: string, subject: string, enquiry: string): Promise<ApiResponse> {
    this.logger.info('Sending enquiry');
    const response = await this.axiosApiInstance.post(`/enquiries/contact-us`, {
        email: email,
        subject: subject,
        enquiry: enquiry,
      })
        .catch(ServiceHandler.handleServiceError);

    const data = ServiceHandler.handleAxiosResponse(response);
    return Object.assign(new ApiResponse(), data)
  }

  async reportError(name: string, email: string, enquiry: string): Promise<ApiResponse> {
    this.logger.info('Reporting error');
    const response = await this.axiosApiInstance.post(`/enquiries/report-error`,
        {
          name, email, enquiry, type: 'crash'
        })
        .catch(ServiceHandler.handleServiceError)


    const data = ServiceHandler.handleAxiosResponse(response);
    return Object.assign(new ApiResponse(), data)
  }

  // ################################################
  // Admin Api
  // ################################################


  async adminPendingRestaurantsGet() : Array<Restaurant> {
    const response = await this.axiosApiInstance.get(`/admin/restaurants/approvals`)
        .catch(ServiceHandler.handleServiceError);

    const data = ServiceHandler.handleAxiosResponse(response);
    return data.map(d => Object.assign(new Restaurant(), d))
  }

  async adminApproveRestaurant(id: string) : any{
    const response = await this.axiosApiInstance.post(`/admin/restaurants/approvals/approve`, {
      restaurant_id: id
    })
        .catch(ServiceHandler.handleServiceError);

    return ServiceHandler.handleAxiosResponse(response, null, null, false);
  }

  async adminRejectRestaurant(id: string) : any{
    const response = await this.axiosApiInstance.post(`/admin/restaurants/approvals/reject`, {
      restaurant_id: id
    })
        .catch(ServiceHandler.handleServiceError);

    return ServiceHandler.handleAxiosResponse(response, null, null, false);
  }

  async adminDeleteRestaurant(id: string) : any{
    const response = await this.axiosApiInstance.post(`/admin/restaurants/delete`, {
      restaurant_id: id
    })
        .catch(ServiceHandler.handleServiceError);

    return ServiceHandler.handleAxiosResponse(response, null, null, false);
  }

  async adminDeleteCategory(type: string, category_name: string) : any{
    const response = await this.axiosApiInstance.delete(`/admin/category/${type}/${category_name}`)
        .catch(ServiceHandler.handleServiceError);

    return ServiceHandler.handleAxiosResponse(response, null, null, false);
  }

  async adminUploadCategoryImageGetUrl(category_type: string, category_name: string) : any{
    const response = await this.axiosApiInstance
        .get(`/admin/images/get-upload-link`, {
          params: {
            type: "category",
            category_type: category_type,
            category_name: category_name,
          }
        })
        .catch(ServiceHandler.handleServiceError);

    return ServiceHandler.handleAxiosResponse(response, null, null, true);
  }

  async adminUploadCategoryImage(type: string, category_name: string, image: any) : any{
    const response = await this.axiosApiInstance.post(`/admin/category/${type}/${category_name}/upload-image`,
        image, {}).catch(ServiceHandler.handleServiceError);

    return ServiceHandler.handleAxiosResponse(response, null, null, false);
  }

  async adminRefreshRestaurant(restaurant_id: string, data: any) : any {
    const response = await this.axiosApiInstance.post(`/admin/restaurants/${restaurant_id}/refresh`,
        data, {}).catch(ServiceHandler.handleServiceError);

    return ServiceHandler.handleAxiosResponse(response, null, null, false);
  }

  async adminFetchRestaurantsCountByFilter(filter: string) : ApiResponse {
    const response = await this.axiosApiInstance.get(`/admin/restaurants/count`,
        {
          params: {filter:filter}
        }).catch(ServiceHandler.handleServiceError);

    return ServiceHandler.handleAxiosResponse(response, null, null, false);
  }

  async adminUpdateRestaurantsPlaceIdMissingPlaces() : ApiResponse<Array<Restaurant>> {
    const response = await this.axiosApiInstance.post(`/admin/restaurants/missing-places`, null, {
      timeout: 65000
    })
        .catch(ServiceHandler.handleServiceError);

    return ServiceHandler.handleAxiosResponse(response, null, null, false);
  }

  async adminFetchAllCategories() : {cuisineCategories: Array<Cuisine>, halalnessCategories: Array<Halalness>, amenityCategories: Array<Amenity>} {
    const response = await this.axiosApiInstance.get('/categories/')
        .catch(ServiceHandler.handleServiceError);

    return Object.assign({
      cuisineCategories: [],
      halalnessCategories: [],
      amenityCategories: [],
    }, response.data);

    const data = ServiceHandler.handleAxiosResponse(response, null, null, false);

    return Object.assign({
      cuisineCategories: [],
      halalnessCategories: [],
      amenityCategories: [],
    }, data);
  }
}

export default new ApiService()
