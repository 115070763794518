import * as types from './actionTypes'
import Logger from '../../utils/Logger';

const INITIAL_STATE = {
  session_token: null,
};

const placesReducer = function(state=INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_PLACES_SESSION_TOKEN:
      return {
        session_token: action.payload
      };
    case types.CLEAR_PLACES_SESSION_TOKEN:
      return {
        session_token: null,
      };
    default:
      return state;
  }
};

export default placesReducer;