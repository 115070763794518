class RestaurantReview {
  id: string;
  rating: number;
  comments: string;
  username: string;
  createdDate: Date;
  modifiedDate: Date;
  restaurantId: string;
  restaurantName: string;

  constructor(
      id: string, rating: number, comments: string, username: string,
      createdDate: Date, modifiedDate: Date, restaurantId: string,
      restaurantName: string) {
    this.id = id;
    this.rating = rating;
    this.comments = comments;
    this.username = username;
    this.createdDate = createdDate;
    this.modifiedDate = modifiedDate;
    this.restaurantId = restaurantId;
    this.restaurantName = restaurantName;
  }
}

export default RestaurantReview;