import axios from 'axios';
import {
  API_AUTH_DELETE_ACCOUNT,
  API_AUTH_LOGIN, APP_FRONT_END_EMAIL_PASS_SIGN_IN,
  APP_FRONT_END_EMAIL_SIGN_IN,
} from '../constants/urls';
import {FIREBASE_CONFIG} from '../config/appConfig';
import firebase from 'firebase/app';
import 'firebase/auth';
import Logger from '../utils/Logger';
import ServiceHandler from './ServiceHandler';
import {ApiResponse} from './apiService';

class AuthService {
  logger = new Logger(AuthService.name);

  constructor() {
    this.logger.info(FIREBASE_CONFIG);
    firebase.initializeApp(FIREBASE_CONFIG);

    this.auth = new firebase.auth();
    this.STORAGE_EMAIL_KEY = "authService.emailForSignIn";
  }

  async getUser() {
    this.logger.info("Getting user");
    const user = this.auth.currentUser
    if (user == null) {
      this.logger.info("Waiting for user");
      await setTimeout(() => this.getUser(), 200)
    } else {
      return Promise.resolve(user)
    }
  }

  async doLoginToServer(token) {
    this.logger.info('logging in to server');
    // const cookie = cookieService.getCsrfCookie();
    // this.logger.info("cookie", cookie);
    let response;
    try {
      response = await axios.post(API_AUTH_LOGIN, token)
      return response;
    } catch (e) {
      ServiceHandler.handleServiceError(e);
    }
  }

  async doFacebookLogin() {
    this.logger.info('logging in via facebook');
    try {
      const provider = new firebase.auth.FacebookAuthProvider();
      const data = await this.auth.signInWithPopup(provider);
      return this.getSuccessResponse(data);
    } catch (err) {
      return this.getFailureResponse(err);
    }
  }

  async doGoogleLogin() {
    this.logger.info('logging in via google');
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      const data = await this.auth.signInWithPopup(provider);
      return this.getSuccessResponse(data);
    } catch (err) {
      return this.getFailureResponse(err);
    }
  }

  async doEmailAndPasswordSignUp(email: string, password: string) {
    this.logger.info('logging in via google');
    try {
      const data = await this.auth.createUserWithEmailAndPassword(email, password);
      return this.getSuccessResponse(data);
    } catch (err) {
      return this.getFailureResponse(err);
    }
  }

  async doEmailAndPasswordLogin(email: string, password: string) {
    this.logger.info('logging in via google');
    try {
      const data = await this.auth.signInWithEmailAndPassword(email, password);
      return this.getSuccessResponse(data);
    } catch (err) {
      return this.getFailureResponse(err);
    }
  }

    async doSendVerificationEmail() {
    this.logger.info('sending verification email');
    try {
      await this.auth.currentUser.sendEmailVerification({
        url: `${window.location.origin}`,
        // This must be true.
        handleCodeInApp: true,
      });
      return this.getSuccessResponse();
    } catch (err) {
      return this.getFailureResponse(err);
    }
  }

  async doEmailLogin(email: string, emailLink: string) {
    this.logger.info('logging in via google');
    try {
      const data = await this.auth.signInWithEmailLink(email, emailLink);
      return this.getSuccessResponse(data);
    } catch (err) {
      return this.getFailureResponse(err);
    }
  }

  async doSendMagicEmailLoginLink(email: string) {
    this.logger.info('logging in via google');
    try {
      // const provider = new firebase.auth.EmailAuthProvider();
      const data = await this.auth.sendSignInLinkToEmail(email, {
        // url: window.location.href,
        // handleCodeInApp: true,
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be whitelisted in the Firebase Console.
        url: `${window.location.origin + APP_FRONT_END_EMAIL_SIGN_IN}`,
        // This must be true.
        handleCodeInApp: true,
        // iOS: {
        //   bundleId: 'com.example.ios'
        // },
        // android: {
        //   packageName: 'com.example.android',
        //   installApp: true,
        //   minimumVersion: '12'
        // },
        // dynamicLinkDomain: HALALMUNCH_SERVICE_API_URL
      });
      window.localStorage.setItem(this.STORAGE_EMAIL_KEY, email);
      return this.getSuccessResponse(data);
    } catch (err) {
      return this.getFailureResponse(err);
    }
  }

  isSignInWithEmailLink(emailLink: string) {
    return this.auth.isSignInWithEmailLink(emailLink);
  }

  async doAnonymousSignIn() {
    this.logger.info('logging in via anonymous');
    try {
      const data = await this.auth.signInAnonymously();
      return this.getSuccessResponse(data);
    } catch (err) {
      return this.getFailureResponse(err);
    }
  }

  async doGetRefreshToken(forceRefresh: boolean = false) {
    this.logger.debug('Fetching user refresh token');
    const result = await this.auth?.currentUser?.getIdToken(forceRefresh);
    this.logger.debug('User refresh token:', result);
    return result;
  }

  async doSignOut() {
    this.logger.info('Signing user out');
    return await this.auth.signOut();
  }

  async deleteAccount(details: string) : ApiResponse {
    this.logger.info('deleting account');
    const id = await this.doGetRefreshToken();
    let response;
    try {
      response = await axios.post(`${API_AUTH_DELETE_ACCOUNT}`, {
        uid: id,
        reason: details
      });
      return Object.assign(new ApiResponse(), response.data);
    } catch (e) {
      ServiceHandler.handleServiceError(e);
    }
  }

  async getRemoteConfig() : ApiResponse {
    if (firebase && firebase.remoteConfig) {
      const remoteConfig = firebase.remoteConfig()
      remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
      remoteConfig.defaultConfig = {
        featured: []
      }
      return remoteConfig;
    } else {
      return null
    }
  }

  async onAuthStateChanged(onAuthStateChangedHandler) {
    return this.auth.onAuthStateChanged((user) => {
      this.logger.debug('onAuthStateChanged called');
      onAuthStateChangedHandler(user);
    });
  }

  getSuccessResponse(data) {
    this.logger.debug('Successful response:', data);
    return {
      ok: true,
      data: data,
    };
  };

  getFailureResponse(err) {
    this.logger.debug('Failure response:', err);
    return {
      ok: false,
      error: err.message,
    };
  };

}

export default new AuthService();
