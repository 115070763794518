export const FETCH_RESTAURANTS_REQUEST = "explore:FETCH_RESTAURANTS_REQUEST";
export const FETCH_RESTAURANTS_SUCCESS = "explore:FETCH_RESTAURANTS_SUCCESS";
export const FETCH_RESTAURANTS_FAILURE = "explore:FETCH_RESTAURANTS_FAILURE";
export const FETCH_RESTAURANTS_EMPTY = "explore:FETCH_RESTAURANTS_EMPTY";

export const UPDATE_RESTAURANTS = "explore:SET_RESTAURANTS";
export const UPDATE_SELECTED_RESTAURANT = "explore:SET_SELECTED_RESTAURANT";
export const UPDATE_SELECTED_CATEGORIES = "explore:SET_SELECTED_CATEGORIES";
export const UPDATE_SELECTED_CUISINE_CATEGORIES = "explore:SET_SELECTED_CUISINE_CATEGORIES";
export const UPDATE_SELECTED_AMENITY_CATEGORIES = "explore:SET_SELECTED_AMENITY_CATEGORIES";

export const UPDATE_MAP_ZOOM = "explore:SET_MAP_ZOOM";
export const UPDATE_MAP_BOUNDS = "explore:SET_MAP_BOUNDS";
export const UPDATE_MAP_VIEWPORT = "explore:UPDATE_MAP_VIEWPORT";


export const SEARCH_RESTAURANTS_REQUEST = "search:SEARCH_RESTAURANTS_REQUEST";
export const SEARCH_RESTAURANTS_SUCCESS = "search:SEARCH_RESTAURANTS_SUCCESS";
export const SEARCH_RESTAURANTS_FAILURE = "search:SEARCH_RESTAURANTS_FAILURE";

export const SEARCH_RESTAURANTS_NEXT_PAGE_REQUEST = "search:SEARCH_RESTAURANTS_NEXT_PAGE_REQUEST";
export const SEARCH_RESTAURANTS_NEXT_PAGE_SUCCESS = "search:SEARCH_RESTAURANTS_NEXT_PAGE_SUCCESS";
export const SEARCH_RESTAURANTS_NEXT_PAGE_FAILURE = "search:SEARCH_RESTAURANTS_NEXT_PAGE_FAILURE";
export const SEARCH_RESTAURANTS_NEXT_PAGE_END = "search:SEARCH_RESTAURANTS_NEXT_PAGE_END";
export const SEARCH_RESTAURANTS_CLEAR = "search:SEARCH_RESTAURANTS_CLEAR";

export const UPDATE_TAB_INDEX = "search:UPDATE_TAB_INDEX";

