import {HALALMUNCH_SERVICE_API_URL} from '../config/appConfig';

const BASE_URL = HALALMUNCH_SERVICE_API_URL;

// Rest api URLS
export const REST_API_HALALNESS_URL = '/api/halalness/';
export const REST_API_CATEGORIES_URL = '/api/categories/';
export const REST_API_RESTAURANTS_URL = '/api/restaurants/';
export const REST_API_RESTAURANT_REVIEWS_URL = '/api/restaurantreviews/';

// Location APIS
export const API_ADDRESS_AUTOCOMPLETE = '/api/places/addresses';
export const API_ADDRESS_AUTOCOMPLETE_TO_LOCATION = '/api/places/location';
export const API_AUTOCOMPLETE_RESTAURANTS = '/api/places/restaurants';
// Restaurant APIS
export const API_RESTAURANTS_URL = '/api/restaurants';
export const API_RESTAURANTS_ADDRESS_URL = '/api/restaurants/address';
export const API_RESTAURANTS_MAP_VIEW_URL = '/api/restaurants/map';
export const API_RESTAURANTS_RECENT_URL = '/api/restaurants/recent';
export const API_RESTAURANTS_NEARBY_URL = '/api/restaurants/nearby';
export const API_RESTAURANTS_AUTOCOMPLETE_URL = '/api/restaurants/autocomplete/';
// Restaurant Home Cook APIS
export const API_RESTAURANT_HOME_COOKS_URL = '/api/restaurant-home-cook';
// Categories
export const API_CATEGORIES_URL = '/api/categories/';
// Auth
export const API_AUTH_LOGIN = '/auth/login';
export const API_AUTH_DELETE_ACCOUNT = '/auth/delete-account';
export const API_AUTH_USER = '/auth/user';
// Error APIS
export const ERROR_REPORT_URL = '/api/error/report/';
// Enquiries APIS
export const API_ENQUIRIES_CONTACT_URL = '/api/enquiries/contact-us';
export const API_ENQUIRIES_REPORT_ERROR_URL = '/api/enquiries/report-error';
export const ENQUIRIES_CONTACT_URL = '/api/enquiries/contact-us';
export const ENQUIRIES_RESTAURANT_SUBMISSION_URL = '/api/enquiries/submit-restaurant';
// User API
export const API_USER = '/api/user';
export const API_USER_REVIEWS = '/api/user/reviews';
export const API_RESTAURANT_LISTS = '/api/restaurant-lists';

// Apple maps
export const NAVIGATION_URL = "https://maps.apple.com/maps?daddr=";

// App pages
export const APP_FRONT_END_EMAIL_SIGN_IN = "/signin/magic-link";
export const APP_FRONT_END_EMAIL_PASS_SIGN_IN = "/signin/email";
export const APP_FRONT_END_EXPLORE = "/explore";
export const APP_FRONT_END_SEARCH = "/search";
export const APP_FRONT_END_SEARCH_LIST = "/search/list";
export const getAppFrontEndSearchListUrl = (username: string, listname: string) => `/${username}/${listname}`;
export const APP_FRONT_END_SEARCH_RESTAURANT = "/search/restaurant";
export const getAppFrontEndSearchRestaurantUrl = (restaurantId: string) => `${APP_FRONT_END_SEARCH_RESTAURANT}/${restaurantId}`;
export const APP_FRONT_END_ENQUIRIES = "/enquiries";
export const APP_FRONT_END_ENQUIRIES_SUBMIT_RESTAURANT = "/account/add-restaurant";
export const APP_FRONT_END_ENQUIRIES_CONTACT_US = "/account/contact-us";
export const APP_FRONT_END_REPORT_ERROR = "/account/report-error";
export const APP_FRONT_END_ACCOUNT = '/account';
export const APP_FRONT_END_ACCOUNT_SIGN_IN = '/account/sign-in';
export const APP_FRONT_END_ACCOUNT_DETAILS = '/account/details';
export const APP_FRONT_END_ACCOUNT_REVIEWS = '/account/reviews';
export const APP_FRONT_END_ACCOUNT_LISTS = '/account/lists';
export const APP_FRONT_END_ACCOUNT_DELETE = '/account/delete';
export const APP_FRONT_END_ACCOUNT_MARKET_ORDERS = '/account/market/orders';
export const APP_FRONT_END_MARKET = '/market';
export const APP_FRONT_END_MARKET_BROWSE = '/market/browse';
export const APP_FRONT_END_MARKET_SIGN_UP = '/market/signup';
export const APP_FRONT_END_MARKET_MY_BUSINESS = '/market/my-business';
export const APP_FRONT_END_MARKET_CHECKOUT = '/market/checkout';
export const APP_FRONT_END_LOGIN = '/login';
