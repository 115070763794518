import * as types from './actionTypes'
import unionBy from 'lodash-es/unionBy';

const INITIAL_STATE = {
    searchQuery: "",
    searchResults: [],
    searchPage: 0,
    searchEnd: false,
    error: null,
    loading: true,
    selectedHalalCategories: [],
    selectedCuisineCategories: [],
    selectedAmenityCategories: [],
};

function searchReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.SEARCH_RESTAURANTS_REQUEST:
            return {
                ...state,
                loading: true,
                searchQuery: action.payload?.searchQuery,
                searchPage: 0,
                searchEnd: false,
                selectedHalalCategories: action.payload?.selectedHalalCategories,
                selectedCuisineCategories: action.payload?.selectedCuisineCategories,
                selectedAmenityCategories: action.payload?.selectedAmenityCategories,
            };
        case types.SEARCH_RESTAURANTS_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case types.SEARCH_RESTAURANTS_SUCCESS:
            return {
                ...state,
                searchResults: action.payload,
                searchPage: state.searchPage + 1,
                loading: false,
            };
        case types.SEARCH_RESTAURANTS_NEXT_PAGE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.SEARCH_RESTAURANTS_NEXT_PAGE_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case types.SEARCH_RESTAURANTS_NEXT_PAGE_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                searchPage: state.searchPage + 1,
                searchResults: unionBy(state.searchResults, action.payload, (r) => r.id)
            };
        case types.SEARCH_RESTAURANTS_NEXT_PAGE_END:
            return {
                ...state,
                searchEnd: true,
            };
        default:
            return state;
    }
}

export const getSelectedHalalCategories = state => state.search.selectedHalalCategories;
export const getSelectedCuisineCategories = state => state.search.selectedCuisineCategories;
export const getSelectedAmenityCategories = state => state.search.selectedAmenityCategories;

export default searchReducer;
