import {extendTheme} from '@chakra-ui/react';

// Chakra UI Theme
const theme = extendTheme({
  config: {
    useSystemColorMode: true,
    initialColorMode: 'dark',
  },
  fonts: {
    heading: "Roboto",
    body: "Roboto",
  },
  colors: {
    "primary": {
      '50': '#fff7f7',
      '100': '#ffeeee',
      '200': '#ffd5d5',
      '300': '#ffbcbc',
      '400': '#ff8989',
      '500': '#ff5757',
      '600': '#e64e4e',
      '700': '#bf4141',
      '800': '#993434',
      '900': '#7d2b2b'
    },
    "secondary": {
      '50': '#f5faff',
      '100': '#ebf5ff',
      '200': '#cde6ff',
      '300': '#afd7fe',
      '400': '#74b8fe',
      '500': '#389afd',
      '600': '#328be4',
      '700': '#2a74be',
      '800': '#225c98',
      '900': '#1b4b7c'
    }
  }
})

export default theme;
