import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import * as Sentry from '@sentry/react';
import rootReducer from './reducers';
import {createLogger} from 'redux-logger/src';

const persistConfig = {
  key: 'halalmunch:state',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  whitelist: [
    "location",
    "explore",
    "search",
    // "popup",
    "basket",
    // "static",
  ]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

let reduxStore;

const sendToNativeApp = store => next => action => {
  const result = next(action)
  if (window.isNativeApp) {
    const message = {
      type: "redux",
      data: store.getState()
    }
    window.ReactNativeWebView.postMessage(JSON.stringify(message))
  }
  return result;
}

const middlewares = [thunk]

if (window.isNativeApp) {
  middlewares.push(sendToNativeApp)
}

if (process.env.NODE_ENV !== 'production' || (!window.isNativeApp && window.LOGGING_debugEnabled)) {
  middlewares.push(createLogger())
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below

});

reduxStore = createStore(
    persistedReducer,
    compose(applyMiddleware(...middlewares), sentryReduxEnhancer),
)

export const store = reduxStore
export const persistor = persistStore(store)
