import * as types from './actionTypes';
import isEmpty from 'lodash-es/isEmpty';
import {BasketCheckoutRequest} from '../../models/models';

const INITIAL_STATE = {
  // popups
  locationPopUp: {
    show: false,
  },
  addressPopUp:  {
    show: false,
  },
  filterPopUp:  {
    show: false,
  },
  listViewPopUp:  {
    show: false,
  },
  restaurantPopUp: {
    show: false,
    expanded: false,
    restaurant: null,
  },
  mapPopUp: {
    show: false,
    restaurants: null,
  },
  addRestaurantPopUp: {
    show: false,
  },
  editRestaurantPopUp: {
    show: false,
    restaurant: null,
  },
  editRestaurantListPopUp: {
    show: false,
    restaurantList: null,
  },
  addItemToBasketPopUp: {
    show: false,
    item: null,
    restaurant: null,
  },
  basketPopUp: {
    show: false,
  },
  checkoutPopUp: {
    show: false,
    basketResponse: null,
    basketCheckoutRequest: null,
  },
  accountPopUp: {
    show: false,
  },
  accountMyDetailsPopUp: {
    show: false,
  },
  accountDeletePopUp: {
    show: false,
  },
  accountMyReviewsPopUp: {
    show: false,
  },
  accountMyListsPopUp: {
    show: false,
  },
  accountMyListsCreatePopUp: {
    show: false,
  },
  accountMyOrdersPopUp: {
    show: false,
  },
  accountOrderDetailPopUp: {
    show: false,
    order: null,
  },
  accountContactPopUp: {
    show: false,
  },
  accountLoginPopUp: {
    show: false,
  },
  restaurantCustomListPopUp: {
    show: false,
    // username: null,
    // listname: null,
    restaurantList: null,
  },
  reportErrorPopUp: {
    show: false,
  },
  ///////////////////////////////
  // ADMIN
  adminPendingPopUp: {
    show: false,
  },
  adminEditRestaurantPopUp: {
    show: false,
  },
  adminEditCategoriesPopUp: {
    show: false,
  },
};

function hideAll(state) {
  return INITIAL_STATE;
}

const popupReducer = (state = INITIAL_STATE, action) => {
  const updatedState = {...state}
  switch (action.type) {
    //------------------------------------------
    // POP UPS
    case types.SHOW_LOCATION_POPUP:
      return {
        ...hideAll(state),
        locationPopUp: {
          show: true,
        },
      };
    case types.SHOW_ADDRESS_POPUP:
      return {
        ...hideAll(state),
        addressPopUp: {
          show: true,
        },
      };
    case types.SHOW_FILTER_POPUP:
      return {
        ...hideAll(state),
        filterPopUp: {
          show: true,
        },
      };
    case types.SHOW_LISTVIEW_POPUP:
      return {
        ...hideAll(state),
        listViewPopUp: {
          show: true,
        },
      };
    case types.SHOW_SELECTED_RESTAURANT_DETAIL_POPUP:
      return {
        ...hideAll(state),
        restaurantPopUp:{
          show: true,
          expanded: false,
          restaurant: action.payload
        }
      };
    case types.SHOW_SELECTED_RESTAURANT_DETAIL_EXPANDED_POPUP:
      return {
        ...hideAll(state),
        restaurantPopUp:{
          show: true,
          expanded: true,
          restaurant: action.payload
        }
      };
    case types.SHOW_EDIT_RESTAURANT_LIST_POPUP:
      return {
        ...hideAll(state),
        editRestaurantListPopUp: {
          show: true,
          restaurantList: action.payload,
        },
      };
    case types.SHOW_ADD_TO_BASKET_POPUP:
      return {
        ...state,
        addItemToBasketPopUp: {
          show: true,
          item: action.payload.item,
          restaurant: action.payload.restaurant,
        },
      };
    case types.SHOW_BASKET_POPUP:
      return {
        ...state,
        basketPopUp: {
          show: true,
        },
      };
    case types.SHOW_ADD_RESTAURANT_POPUP:
      return {
        ...state,
        addRestaurantPopUp: {
          show: true,
        },
      };
    case types.HIDE_POPUP:
      const popupToHide = action.payload;
      if (!isEmpty(popupToHide)) {
        if (updatedState && updatedState[popupToHide] != null) {
          updatedState[popupToHide].show = false;
          return updatedState
        } else {
          return INITIAL_STATE;
        }
      } else {
        return INITIAL_STATE;
      }
    case types.SHOW_POPUP:
      const popupToShow = action.payload.popUp;
      const data = action.payload.data || {};
      if (!isEmpty(popupToShow)) {
        if (updatedState && updatedState[popupToShow] != null) {
          updatedState[popupToShow] = {
            show: true,
            ...data
          }
          return updatedState
        } else {
          return INITIAL_STATE;
        }
      } else {
        return INITIAL_STATE;
      }
    default:
      return state;
  }
};

export const shouldShowRestaurantPopUp = state => state.popup.restaurantPopUp.show;
export const getRestaurantPopUpRestaurant = state => state.popup.restaurantPopUp.restaurant;

export const shouldShowLocationPopUp = state => state.popup.locationPopUp.show;
export const shouldShowAddressPopUp = state => state.popup.addressPopUp.show;
export const shouldShowFilterPopUp = state => state.popup.filterPopUp.show;
export const shouldShowListViewPopUp = state => state.popup.listViewPopUp.show;
export const shouldShowEditRestaurantListPopUp = state => state.popup.editRestaurantListPopUp.show;
export const getEditRestaurantListPopUpRestaurantList = state => state.popup.editRestaurantListPopUp.restaurantList;
export const shouldShowMapPopUp = state => state.popup.mapPopUp.show;
export const getShowMapPopUpRestaurants = state => state.popup.mapPopUp.restaurants;
export const shouldShowAddItemToBasketPopUp = state => state.popup.addItemToBasketPopUp.show;
export const getAddItemToBasketPopUpItem = state => state.popup.addItemToBasketPopUp.item;
export const getAddItemToBasketPopUpRestaurant = state => state.popup.addItemToBasketPopUp.restaurant;
export const shouldShowBasketPopUp = state => state.popup.basketPopUp.show;
export const shouldShowCheckoutPopUp = state => state.popup.checkoutPopUp.show;
export const shouldShowAddRestaurantPopUp= state => state.popup.addRestaurantPopUp.show;
export const shouldShowEditRestaurantPopUp= state => state.popup.editRestaurantPopUp.show;
export const getEditRestaurantPopUpRestaurant= state => state.popup.editRestaurantPopUp.restaurant;
export const shouldShowAccountPopUp = state => state.popup.accountPopUp.show;
export const shouldShowAccountMyDetailsPopUp = state => state.popup.accountMyDetailsPopUp.show;
export const shouldShowAccountDeletePopUp = state => state.popup.accountDeletePopUp.show;
export const shouldShowAccountMyReviewsPopUp = state => state.popup.accountMyReviewsPopUp.show;
export const shouldShowAccountMyListsPopUp = state => state.popup.accountMyListsPopUp.show;
export const shouldShowAccountMyListsCreatePopUp = state => state.popup.accountMyListsCreatePopUp.show;
export const shouldShowAccountMyOrdersPopUp = state => state.popup.accountMyOrdersPopUp.show;
export const shouldShowOrderDetailsPopUp = state => state.popup.accountOrderDetailPopUp.show;
export const getOrderDetailsPopUpOrder = state => state.popup.accountOrderDetailPopUp.order;
export const shouldShowAccountContactPopUp = state => state.popup.accountContactPopUp.show;
export const shouldShowAccountLoginPopUp = state => state.popup.accountLoginPopUp.show;
export const shouldShowRestaurantCustomListPopUpPopUp = state => state.popup.restaurantCustomListPopUp.show;
export const shouldShowReportErrorPopUp = state => state.popup.reportErrorPopUp.show;
// Admin
export const shouldShowAdminPendingPopUp = state => state.popup.adminPendingPopUp.show;
export const shouldShowAdminEditRestaurantPopUp = state => state.popup.adminEditRestaurantPopUp.show;
export const shouldShowAdminEditCategoriesPopUp = state => state.popup.adminEditCategoriesPopUp.show;

export default popupReducer;
