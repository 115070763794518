import React, {useState} from 'react';
import ViewWrapper from '../common/ViewWrapper';
import ErrorMessage from './ErrorMessage';
import PropType from 'prop-types';
import {ActionMessage} from './InfoMessage';
import {persistor} from '../../store/configureStore';
import * as Sentry from "@sentry/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Stack,
  Button,
  Input,
  Textarea,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Alert,
} from '@chakra-ui/react';
import apiService from '../../services/apiService';


export default class ErrorMessageBoundary extends React.Component {
  static propTypes = {
    message: PropType.string,
  };

  static defaultProps = {
    message: "Failed to load component"
  };

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorSent: null,
    };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({hasError: true});
    // You can also log the error to an error reporting service
    console.log('error', error);
    console.log('info', info);
    Sentry.captureException(error)
  }

  render() {
    return (
        <React.Fragment>
          {this.state.hasError ?
              <ViewWrapper style={{display: 'flex', flexDirection: 'column', alignItems: 'center', flex:1}}>

                <ErrorMessage message={this.props.message}/>

              </ViewWrapper>
              :
              this.props.children
          }
        </React.Fragment>
    );
  }
}

export class MajorErrorBoundary extends React.Component {

  static defaultProps = {

  };

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({hasError: true});
    // You can also log the error to an error reporting service
    console.log('error', error);
    console.log('info', info);

    Sentry.captureException(error)
  }


  render() {
    return (
        <React.Fragment>
          {this.state.hasError ?
              <ErrorMessageBoundary>
                <CrashReportModal/>
              </ErrorMessageBoundary>
              :
              this.props.children
          }
        </React.Fragment>
    );
  }
}

function CrashReportModal(props) {

  const [isLoading, setIsloading] = useState(false);
  const [hasErrorSent, setHasErrorSent] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [enquiry, setEnquiry] = useState('');

  function onErrorFormSubmit() {
    setIsloading(true)
    apiService.reportError(name, email, enquiry)
        .then(() => setHasErrorSent(true))
        .catch()
        .finally(() => setIsloading(false))
  }

  function reload() {
    persistor.purge()
        .then(() => window.location.reload())
        .catch(() => null)
  }

  return (
      <Modal finalFocusRef={null} isOpen={true} onClose={reload}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Oh no, it looks like our app has crashed.</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {hasErrorSent
                ?
                <Alert
                    status={'success'}
                    variant='subtle'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                    textAlign='center'
                    height='200px'
                >
                  <AlertIcon boxSize='40px' mr={0} />
                  <AlertTitle mt={4} mb={1} fontSize='lg'>
                    Thanks, your message has been received and we'll try to get this fixed as soon as we can.
                    <br/>
                  </AlertTitle>
                </Alert>
                :
                <Stack spacing={6}>
                  <Text>
                    Our team have been notified. If you'd like to help,
                    tell us what happened below.
                  </Text>
                  <Stack spacing={1}>
                    <Text>Name</Text>
                    <Input type={'text'} placeholder={'Halal Hasan'}
                           onChange={e => setName(e.target.value)}/>
                  </Stack>
                  <Stack spacing={1}>
                    <Text>Email</Text>
                    <Input type={'email'} placeholder={'hasan@halalmunch.com'}
                           onChange={e => setEmail(e.target.value)}/>
                  </Stack>
                  <Stack spacing={1}>
                    <Text>What happened?</Text>
                    <Textarea placeholder={"I clicked on 'X' and then pressed 'Submit'"}
                              onChange={e => setEnquiry(e.target.value)}/>
                  </Stack>
                </Stack>
            }


          </ModalBody>

          <ModalFooter>
            {!hasErrorSent &&
            <Button bg={'primary.500'} color={'white'} mr={3} onClick={onErrorFormSubmit}
                    isFullWidth isLoading={isLoading}>
              Submit Crash Report
            </Button>
            }
            <Button isFullWidth onClick={reload}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
  )
}
