import {Restaurant} from './models';

class PostRestaurantResponse {
  restaurant: Restaurant;
  restaurantAddress: RestaurantAddress;
  restaurantContact: RestaurantContact;
  sessionToken: string;

  constructor(
      restaurant: Restaurant, restaurantAddress: RestaurantAddress,
      restaurantContact: RestaurantContact, sessionToken: string) {
    this.restaurant = restaurant;
    this.restaurantAddress = restaurantAddress;
    this.restaurantContact = restaurantContact;
    this.sessionToken = sessionToken;
  }
}

export default PostRestaurantResponse;
