// popups
export const SHOW_LOCATION_POPUP = "popup:SHOW_LOCATION_POPUP";
export const SHOW_ADDRESS_POPUP = "popup:SHOW_ADDRESS_POPUP";
export const SHOW_FILTER_POPUP = "popup:SHOW_FILTER_POPUP";
export const SHOW_LISTVIEW_POPUP = "popup:SHOW_LISTVIEW_POPUP";
export const SHOW_SELECTED_RESTAURANT_DETAIL_POPUP = "popup:SHOW_SELECTED_RESTAURANT_DETAIL_POPUP";
export const SHOW_SELECTED_RESTAURANT_DETAIL_EXPANDED_POPUP = "popup:SHOW_SELECTED_RESTAURANT_DETAIL_EXPANDED_POPUP";
export const SHOW_EDIT_RESTAURANT_LIST_POPUP = "popup:SHOW_EDIT_RESTAURANT_LIST_POPUP";
export const SHOW_ADD_TO_BASKET_POPUP = "popup:SHOW_ADD_TO_BASKET_POPUP";
export const SHOW_BASKET_POPUP = "popup:SHOW_BASKET_POPUP";
export const SHOW_ADD_RESTAURANT_POPUP = "popup:SHOW_ADD_RESTAURANT_POPUP";
export const HIDE_POPUP = "popup:HIDE_POPUP";
export const SHOW_POPUP = "popup:SHOW_POPUP";
export const SHOW_ACCOUNT_MY_DETAILS_POPUP = "popup:SHOW_ACCOUNT_MY_DETAILS_POPUP";
export const SHOW_ACCOUNTMYREVIEWSPOPUP = "popup:SHOW_ACCOUNTMYREVIEWSPOPUP";
export const SHOW_ACCOUNTMYLISTSPOPUP = "popup:SHOW_ACCOUNTMYLISTSPOPUP";
export const SHOW_ACCOUNTCONTACTPOPUP = "popup:SHOW_ACCOUNTCONTACTPOPUP";
export const SHOW_ADMINPENDINGPOPUP = "popup:SHOW_ADMINPENDINGPOPUP";
