import * as types from './actionTypes';
import {
  Restaurant,
  MenuItem,
  MenuItemVariation,
  MenuItemVariationOption, BasketItem, Order, CheckoutDateTimeSlots,
} from '../../models/models';
import groupBy from 'lodash-es/groupBy';

export type IBasketItem = {
  item: MenuItem,
  variations: Array<MenuItemVariation>,
  quantity: number,
};

type StateType = {
  isLoading: boolean,
  message: string,
  error: string,
  items: Array<BasketItem>,
  restaurant: Restaurant,
  totalItems: number,
  totalPrice: number,
  orderTime: Date,
  orderId: string,
  order: Order,
  is_existing_order: boolean,
  client_secret: string,
  connected_account: string,
  has_order_changed: boolean,
  available_date_time_slots: Array<CheckoutDateTimeSlots>,
}

const INITIAL_STATE: StateType = {
  isLoading: false,
  message: null,
  error: null,
  items: [],
  restaurant: null,
  totalItems: 0,
  totalPrice: 0,
  orderTime: null,
  orderId: null,
  order: null,
  is_existing_order: false,
  client_secret: null,
  connected_account: null,
  has_order_changed: false,
  available_date_time_slots: [],
};

function persistState(data) {
  window.localStorage.setItem("halalmunch:basket", JSON.stringify(data));
  return data;
}

function loadPersistedState() {
  const dataStr = window.localStorage.getItem("halalmunch:basket");
  return {INITIAL_STATE, ...JSON.parse(dataStr)};
}

const basketReducer = function(state = INITIAL_STATE, action) {
  let basketItems = []
  if (state.items && state.items.length > 0) {
    basketItems = new Array(...state.items.map(b => new BasketItem(b.item, b.variations, b.quantity, b.price)));
  }
  let index = undefined;
  if (action?.type === types.LOAD_BASKET) {
    const order: Order = action?.payload?.order
    const connected_account = action?.payload?.connected_account
    const client_secret = action?.payload?.client_secret
    const is_existing_order = action?.payload?.is_existing_order
    const has_order_changed = action?.payload?.has_order_changed
    const basket_items = action?.payload?.basket_items
    const available_date_time_slots = action?.payload?.available_date_time_slots
    if (order) {
      let error = null
      try {
        basketItems = basket_items?.map(i => Object.assign(new BasketItem(), i))
        basketItems = order?.items?.map(orderItem => {
          const optionsByVariationIdMap = groupBy(orderItem?.options ? orderItem?.options : {}, 'variation_id')
          console.log("optionsByVariationId", optionsByVariationIdMap)
          const variations = Object.entries(optionsByVariationIdMap).map(([vId, options]) => {
            const variation = new MenuItemVariation(vId)
            if (options && options?.length > 0) {
              variation.options = options?.map(op => Object.assign(new MenuItemVariationOption(), ...op))
            }
            return variation
          })
          // const variations = optionsByVariationId ?
          //     optionsByVariationId.map((options, vId) => {
          //       const variation = new MenuItemVariation(vId)
          //       if (options && options?.length > 0) {
          //         variation.options = options?.map(op => Object.assign(new MenuItemVariationOption(), ...op))
          //       }
          //       return variation
          //     })
          //     : [];
          return (
              new BasketItem(
                  orderItem?.item,
                  variations,
                  orderItem?.quantity,
                  orderItem?.price
              )
          )
        })
      } catch (e) {
        console.error("basket load error", e)
      }
      return {
        ...state,
        order: order,
        available_date_time_slots: available_date_time_slots,
        connected_account: connected_account,
        client_secret: client_secret,
        is_existing_order: is_existing_order,
        has_order_changed: has_order_changed,
        items: basketItems ? basketItems : [],
        restaurant: order?.restaurant,
        orderTime: order?.order_date,
        orderId: order?.id,
      }
    } else {
      return {
        ...state,
        isLoading: false,
        order: null,
        connected_account: null,
        client_secret: null,
        is_existing_order: false,
        items: [],
        restaurant: null,
        orderTime: null,
        orderId: null,
      }
    }
  }
  else if (action?.type === types.LOAD_CACHED_BASKET) {
    const loadedState = loadPersistedState();
    console.log("loadedState", loadedState)
    console.log("state.items", state.items)
    if ((state.items && state.items.length === 0) && loadedState) {
      return {
        ...loadedState,
        isLoading: false
      };
    } else {
      return state;
    }
  }
  else if (action?.type === types.UPDATE_ALL_ITEMS_BASKET) {
    return persistState({
      ...state,
      isLoading: false,
      items: action?.payload.items ? action?.payload.items : [],
    })
  }
  else if (action?.type === types.ADD_ITEM_TO_BASKET) {
    const item = action.payload.item;
    const variations = action.payload.variations;
    const quantity = action.payload.quantity;
    const restaurant = action.payload.restaurant;

    const variationObjects = variations.map(v =>
        new MenuItemVariation(v.id, null, v.variation_name, null, null, null,
            null, null,
            v.options.map(
                op => new MenuItemVariationOption(op.id, v.id, op.option_name))
        ))
    index = findIndexOfExistingItem(basketItems, item.id, variations)
    if (index > -1) {
      basketItems[index].quantity += quantity
    } else {
      basketItems.push({item: item, variations: variationObjects, quantity: quantity})
    }
    return persistState({
      ...state,
      items: basketItems ? basketItems : [],
      restaurant: restaurant,
      isLoading: false,
    });
  }
  else if (action?.type === types.REMOVE_ITEM_FROM_BASKET) {
    const id = action.payload.item_id;
    const variations = action.payload.variations;

    index = findIndexOfExistingItem(basketItems, id, variations)

    if (index > -1) {
      console.log("found item to remove")
      basketItems[index].quantity = basketItems[index].quantity - 1
      if (basketItems[index].quantity === 0) {
        basketItems.splice(index, 1)
      }
    } else {
      console.log("not found item to remove")

    }

    return persistState({
      ...state,
      items: basketItems ? [...basketItems] : [],
      restaurant: basketItems.length === 0 ? null : state.restaurant,
      isLoading: false,
    });
  }
  else if (action?.type === types.CLEAR_BASKET) {
    return persistState(INITIAL_STATE);
  }
  else if (action?.type === types.BASKET_ERROR) {
    return {
      ...state,
      error: action?.payload?.error
    }
  }
  else if (action?.type === types.BASKET_LOADING) {
    return {
      ...state,
      isLoading: action?.payload?.loading
    }
  } else {
      return state;
  }
};

function findIndexOfExistingItem(currentItems: Array<MenuItem>, itemId: string, variations: Array<MenuItemVariation>) {
  return  currentItems.findIndex((currentItem, index) => {
    const itemIdEqual = currentItem.item.id === itemId
    const existingOptions = currentItem.variations.map(v => v?.options?.map(op => op.option_name).sort().join(",")).join(",")
    const newItemOptions = variations.map(v => v?.options?.map(op => op.option_name).sort().join(",")).join(",")
    return itemIdEqual && existingOptions === newItemOptions
  })
}

export const getBasketTotalItems = (state) => state.basket?.items?.reduce((total, item) => total + item.quantity, 0)
export const getBasketItems = (state) => state.basket?.items
export const getBasketOrder = (state) => state.basket?.order
export const getBasketConnectedAccount = (state) => state.basket?.connected_account
export const getBasketClientSecret = (state) => state.basket?.client_secret
export const getBasketMessage = (state) => state.basket?.message
export const isBasketExistingOrder = (state) => state.basket?.is_existing_order
export const isBasketLoading = (state) => state.basket?.isLoading
export const hasBasketOrderChanged = (state) => state.basket.has_order_changed
export const getAvailableOrderDates = (state) => state.basket.available_date_time_slots


export default basketReducer;
