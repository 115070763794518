import {AxiosError, AxiosResponse} from 'axios';
import Logger from '../utils/Logger';
import {isEmpty} from 'lodash-es';
import * as Sentry from "@sentry/browser";

const DEFAULT_4XX_ERROR = "Oh no, a bad request was received, please try again.";
const DEFAULT_403_ERROR = "You are not authorised please sign in or try reloading the page.";
const DEFAULT_404_ERROR = "404 Not Found. Sorry we are unable to find what you're looking for.";
const DEFAULT_5XX_ERROR = "Oops something went wrong on our side, please try again.";
const DEFAULT_NO_RESPONSE_ERROR = "Uh-oh, we failed to fetch data, please try refreshing the page.";
const DEFAULT_PERMISSION_ERROR = "You do not have permission to access this.";
class ServiceHandler {
  static logger = new Logger(ServiceHandler.name);

  static handleAxiosResponse(response : AxiosResponse, default4XXError = DEFAULT_4XX_ERROR, default5XXError = DEFAULT_5XX_ERROR, dataOnly=true) {
    const status = response.status;
    const defaultError = default4XXError || default5XXError
    if (ServiceHandler._is2xxError(status)) {
      const data = response.data
      if (data.result) {
        return dataOnly ? data.result : data;
      } else {
        return data
      }
    } else if(ServiceHandler._isPermissionError(status)) {
      throw new Error(DEFAULT_PERMISSION_ERROR);
    } else if(ServiceHandler._is4xxError(status)) {
      throw new Error(defaultError || DEFAULT_4XX_ERROR);
    } else if (ServiceHandler._is5xxError(status)) {
      throw new Error(defaultError || DEFAULT_5XX_ERROR);
    } else {
      throw new Error(defaultError || DEFAULT_5XX_ERROR);
    }
  }

  // static handleAxiosResponse(response : AxiosResponse, dataOnly=true) {
  //   return this.handleAxiosResponse(response, DEFAULT_4XX_ERROR, DEFAULT_5XX_ERROR, dataOnly)
  // }

  static _is2xxError(status) {
    return status.toString().startsWith(2);
  }

  static _is404Error(status) {
    return status.toString().startsWith(404);
  }

  static _is403Error(status) {
    return status.toString().startsWith(403);
  }

  static _is4xxError(status) {
    return status.toString().startsWith(4);
  }

  static _isPermissionError(status) {
    return status === 401 || status === 402 || status === 403;
  }

  static _is5xxError(status) {
    return status.toString().startsWith(5);
  }

  static handleServiceError(err: AxiosError, default4XXError, default5XXError) {
    const response = err.response;
    const defaultError = default4XXError || default5XXError

    Sentry.captureException(err)

    if (response == null) {
      throw new Error(DEFAULT_NO_RESPONSE_ERROR)
    }
    const status = response.status;

    const data = response.data;

    ServiceHandler.logger.error("Error response", response);

    if(ServiceHandler._is404Error(status)) {
      ServiceHandler.logger.error("404 error", status);
      throw new Error(DEFAULT_404_ERROR)
    }

    if(ServiceHandler._is403Error(status)) {
      ServiceHandler.logger.error("403 error", status);
      throw new Error(DEFAULT_403_ERROR)
    }

    if (!isEmpty(data) && !isEmpty(data.message)) {
      ServiceHandler.logger.error("Error from backend with message", data.message);
      throw new Error(data.message);
    } else if (!isEmpty(data) && !isEmpty(data.error)) {
      ServiceHandler.logger.error("Error from backend with message", data.error);
      throw new Error(data.error);
    }
    else if(ServiceHandler._isPermissionError(status)) {
      ServiceHandler.logger.error("Permissions error", status);
      throw new Error(DEFAULT_PERMISSION_ERROR)
    } else if(ServiceHandler._is404Error(status)) {
      ServiceHandler.logger.error("404 error", status);
      throw new Error(DEFAULT_404_ERROR)
    } else if(ServiceHandler._is4xxError(status)) {
      ServiceHandler.logger.error("4XX error", status);
      throw new Error(defaultError || DEFAULT_4XX_ERROR)
    } else if(ServiceHandler._is5xxError(status)) {
      ServiceHandler.logger.error("5XX error", status);
      throw new Error(defaultError || DEFAULT_5XX_ERROR)
    } else {
      ServiceHandler.logger.error("Unknown error", status);
      throw new Error(defaultError || DEFAULT_5XX_ERROR)
    }
  }

}

export default ServiceHandler;
