import React, {createContext, useEffect, useState} from 'react';

export const NativeAppContext = createContext({
  isNativeApp: true,
  isNativeAppTrackingEnabled: false,
  sendMessage: (type: string, data: any) => null,
})

export default function NativeAppProvider(props) {
  const [isNativeApp, setIsNativeApp] = useState(false)
  const [isNativeAppTrackingEnabled, setIsNativeAppTrackingEnabled] = useState(false)

  useEffect(() => {
    setIsNativeApp(window.isNativeApp || false)
  }, [window.isNativeApp]);

  useEffect(() => {
    setIsNativeAppTrackingEnabled(window.NATIVEAPP_trackingEnabled || false)
  }, [window.NATIVEAPP_trackingEnabled]);

  function sendMessage(type, data) {
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
      const message = {
        type: type,
        data: data
      }
      window.ReactNativeWebView.postMessage(JSON.stringify(message))
    }
  }

  return (
      <NativeAppContext.Provider
          value={{
            isNativeApp: isNativeApp,
            isNativeAppTrackingEnabled: isNativeAppTrackingEnabled,
            sendMessage: sendMessage
          }}
      >
        {props?.children}
      </NativeAppContext.Provider>
  )
}
