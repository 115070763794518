import {keyframes} from "styled-components";

export const SlideRightToLeftEntryKeyFrame = keyframes`
  0% {
    right: -100%;
  }
  100% {
    right: 0;
  }
`;

export const SlideRightToLeftExitKeyFrame = keyframes`
  0% {
    right: 0;
  }
  100% {
    right: -100%;
  }
`;



export const SlideRightToLeftMarginEntryKeyFrame = keyframes`
  0% {
    margin-right: -100vw;
  }
  100% {
    margin-right: 0;
  }
`;

export const SlideRightToLeftMarginExitKeyFrame = keyframes`
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -200%;
  }
`;