import {combineReducers} from 'redux';
import authReducer from './auth/reducers';
import placesReducer from "./places/placesReducer";
import staticReducer from "./static/staticReducer";
import locationReducer from "./location/locationReducer";
import exploreReducer from "./explore/exploreReducer";
import popupReducer from "./popup/popupReducer";
import searchReducer from "./search/searchReducer";
import notificationReducer from './notification/notificationReducer';
import basketReducer from './basket/basketReducer';

export default combineReducers({
  places: placesReducer,
  static: staticReducer,
  location: locationReducer,
  explore: exploreReducer,
  popup: popupReducer,
  auth: authReducer,
  search: searchReducer,
  notification: notificationReducer,
  basket: basketReducer,
})
