import * as types from './actionTypes';
import NotificationBanner
  , {notificationTypes} from '../../components/notification/NotificationBanner';

const INITIAL_STATE = {
  // popups
  message: null,
  type: null,
  autoClose: false,
};

const notificationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SHOW_INFO_NOTIFICATION:
      return {
        ...state,
        message: action.payload.message,
        autoClose: action.payload.autoClose,
        type: notificationTypes.INFO
      };
    case types.SHOW_ERROR_NOTIFICATION:
      return {
        ...state,
        message: action.payload.message,
        autoClose: action.payload.autoClose,
        type: notificationTypes.ERROR
      };
    case types.SHOW_SUCCESS_NOTIFICATION:
      return {
        ...state,
        message: action.payload.message,
        autoClose: action.payload.autoClose,
        type: notificationTypes.SUCCESS
      };
    case types.REMOVE_NOTIFICATION:
      return {
        ...state,
        message: null,
        autoClose: true,
        type: null
      };
    default:
      return state;
  }
};

export const getNotificationMessage = state => state.notification.message;
export const getNotificationType = state => state.notification.type;
export const shouldNotificationAutoClose = state => state.notification.autoClose;

export default notificationReducer;