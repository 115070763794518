import React, {createContext} from 'react';
import ApiService from "../services/apiService";
import axios from "axios";
import {toast, useToast} from "@chakra-ui/react";


interface NotificationContextProps {
  success: ({title: string, message: string}) => void,
  error: ({title: string, message: string}) => void,
  info: ({title: string, message: string}) => void,
}

export const NotificationContext = createContext({
  success: ({title, message}) => null,
  error: ({title, message}) => null,
  info: ({title, message}) => null,
});


export function NotificationProvider(props) {

  const toast = useToast();
  function success({title = "Success", message = ""}) {
    toast({
      title: title,
      description: message,
      status: "success",
      duration: 5000,
      isClosable: true,
      position: 'top'
    })
  }

  function error({title = "Error", message = ""}) {
    toast({
      title: title,
      description: message,
      status: "error",
      duration: 5000,
      isClosable: true,
      position: 'top'
    })
  }

  function info({title = "Info", message = ""}) {
    toast({
      title: title,
      description: message,
      status: "info",
      duration: 5000,
      isClosable: true,
      position: 'top'
    })
  }

  return (
      <NotificationContext.Provider
          value={{
            success: success,
            error: error,
            info: info,
          }}
      >
        {props.children}
      </NotificationContext.Provider>
  )
}
