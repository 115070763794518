import React, {useEffect, useState} from 'react';
import styled, {css} from "styled-components";
import {
  FONT_LIGHT,
  COLOR_DANGER,
  COLOR_INFO,
  COLOR_SUCCESS, zIndexes,
} from '../../constants/styles';
import {SlideRightToLeftMarginExitKeyFrame} from "../keyframes/SlideRightToLeftKeyFrame";
import {FAST_ANIMATION_TIME_MILLIS} from "../../constants/animation";
import FlexView from "../views/FlexView";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";
import ViewWrapper from '../common/ViewWrapper';

export const notificationTypes = {
  ERROR: "ERROR",
  INFO: "INFO",
  SUCCESS: "SUCCESS",
};

type Props = {
  type: string,
  onClose: () => void,
  autoClose: boolean,
}

function NotificationBanner(props : Props) {
  const {type=notificationTypes.INFO, onClose, autoClose=true} = {...props};

  const [hide, setHide] = useState(false);

  useEffect(() => {
    let timeout;
    if (autoClose) {
      setTimeout(() => setHide(true), 5000);
    }
  }, []);

  useEffect(() => {
    if (hide) {
      setTimeout(onClose, FAST_ANIMATION_TIME_MILLIS)
    }
  }, [hide]);

  return (
      <Banner type={type} hide={hide}>
        <BannerText>
          {props.children}
        </BannerText>
        <CloseButton onClick={() => setHide(true)}>
          <FontAwesomeIcon icon={faTimes}/>
        </CloseButton>
      </Banner>
  );
}



const Banner = styled(FlexView).attrs({alignItems: "center", justifyContent: "center"})`
  position: absolute;
  top:20px;
  z-index: ${zIndexes.NOTIFICATION_LAYER};
  display: flex;
  font-weight: ${FONT_LIGHT};
  padding: 10px 0;
  width: 90%;
  border-radius: 5px;
  text-align: center;
  background-color: ${props => props.error ? COLOR_DANGER : COLOR_SUCCESS};
  color: #FFFFFF;
  animation-name: ${props => props.hide && SlideRightToLeftMarginExitKeyFrame};
  animation-duration: ${FAST_ANIMATION_TIME_MILLIS}ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-direction: normal;
  min-height: 30px;
  ${props => props.type === notificationTypes.ERROR && css`
      background-color: ${COLOR_DANGER };
  `}
  ${props => props.type === notificationTypes.SUCCESS && css`
      background-color: ${COLOR_SUCCESS };
  `}
  ${props => props.type === notificationTypes.INFO && css`
      background-color: ${COLOR_INFO };
  `}
`;
Banner.displayName = "Banner";

const BannerText = styled(ViewWrapper)`
  width: 85%;
`;

const CloseButton = styled(ViewWrapper)`
  height: 100%;
  width: 50px;
  color: #FFFFFF;
  padding: 2px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
`;

export default NotificationBanner;
