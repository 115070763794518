class AutocompletePrediction {
    id: string;
    description:string;

    constructor(id: string, description: string) {
        this.id = id;
        this.description = description;
    }
}

class AutocompleteResponse {
    autocompletePredictions: Array<AutocompletePrediction>;
    sessionToken: string;

    constructor(autocompletePredictions: Array<AutocompletePrediction>, sessionToken: string) {
        this.autocompletePredictions = autocompletePredictions;
        this.sessionToken = sessionToken;
    }
}

export {
    AutocompletePrediction
};

export default AutocompleteResponse;