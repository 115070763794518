import React, {Fragment, ReactChild, ReactChildren, useContext} from 'react';
import ViewWrapper from '../common/ViewWrapper';
import TextWrapper from '../common/TextWrapper';
import {
  COLOR_INFO,
} from '../../constants/styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationTriangle, faInfo} from '@fortawesome/free-solid-svg-icons';
import {Button, Flex, Stack, Text} from '@chakra-ui/react';
import {ResponsiveContext} from '../../provider/ResponsiveProvider';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

type Props = {
  message?: string,
  icon?: IconProp,
  iconColor?: string,
}

export function Message(props) {

  const {isMobile, bgColor, fontColor} = useContext(ResponsiveContext)

  const {message, icon, iconColor=COLOR_INFO} = {...props}

  return (
      <Stack
          boxShadow={"0 2px 5px rgb(0 0 0 / 10%)"}
          borderRadius={"5px"}
          backgroundColor={bgColor}
          color={fontColor}
          padding={"15px 10px"}
          margin={"10px 20px !important"}
          justify={"center"}
          align={"center"}
          minHeight={"max-content"}
      >
        {icon && <FontAwesomeIcon size={"lg"} icon={icon} color={iconColor} style={{height: '50px'}}/>}
        {message && <Text paddingTop={"10px"}>{message}</Text>}
        {props?.children}
      </Stack>
  )
}

function InfoMessage(props : {message: string, children?: ReactChild | ReactChildren}) {

  return (
      <Message {...props} icon={faInfo}>
      </Message>
  )
}


type ActionMessageProps = {
  message: string,
  actionText?: string,
  onAction?: () => void,
}
export function ActionMessage(props : ActionMessageProps) {

  const {message, actionText="Retry", onAction=()=>null} = {...props}
  return (
      <Message icon={faInfo} iconColor={COLOR_INFO} message={message}>
        <Button onClick={onAction}>{actionText}</Button>
      </Message>
  )
}

export default InfoMessage;
