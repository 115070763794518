import Halalness from './Halalness';
import RestaurantContact from './RestaurantContact';
import RestaurantReview from './RestaurantReview';
import dayjs from 'dayjs';

export class UserAccount {
    id: string;
    username: string;
    role: string;
    firebase_uid: string;

    constructor(
        id: string, username: string, role: string, firebase_uid: string) {
        this.id = id;
        this.username = username;
        this.role = role;
        this.firebase_uid = firebase_uid;
    }
}

export class UserAccountPublic {
    username: string;

    constructor(username: string) {
        this.username = username;
    }
}





// ----------------------------------------
// MENU START
// ----------------------------------------


export class MenuItemVariationOption {
    id: number;
    variation_id: string;
    option_name: string;
    price_change: number;
    updated_at: Date;
    created_at: Date;

    constructor(id: number, variation_id: string, option_name: string, price_change: number, updated_at: Date, created_at: Date) {
        this.id = id;
        this.variation_id = variation_id;
        this.option_name = option_name;
        this.price_change = price_change || 0;
        this.updated_at = updated_at;
        this.created_at = created_at;
    }
}

export class MenuItemVariation {
    id: number;
    item_id: string;
    variation_name: string;
    description: string;
    option_min: number;
    option_max: number;
    updated_at: Date;
    created_at: Date;
    options: Array<MenuItemVariationOption>;

    constructor(id: number, item_id: string, variation_name: string, description: string, option_min: number, option_max: number, updated_at: Date, created_at: Date, options: Array<MenuItemVariationOption>) {
        this.id = id;
        this.item_id = item_id;
        this.variation_name = variation_name;
        this.description = description;
        this.option_min = option_min || 1;
        this.option_max = option_max;
        this.updated_at = updated_at;
        this.created_at = created_at;
        this.options = options != null ?
            options.map(op => op != null ?
                new MenuItemVariationOption(
                    op.id,
                    op.variation_id,
                    op.option_name,
                    op.price_change,
                    op.updated_at,
                    op.created_at,
            ) : null) || [new MenuItemVariationOption()]
            : [];
    }
}

export class MenuItem {
    id: number;
    restaurant_id: string;
    description: string;
    view_order_priority: number;
    item_name: string;
    item_short_name: string;
    dietary_comments: string;
    price: number;
    discounted_price: number;
    available: boolean;
    item_group_id: string;
    variations: Array<MenuItemVariation>;
    updated_at: Date;
    created_at: Date;


    constructor(id: number, restaurant_id: string, description: string, view_order_priority: number, item_name: string, item_short_name: string, dietary_comments: string, price: number, discounted_price: number, available: boolean, item_group_id: string, variations: Array<MenuItemVariation>, updated_at: Date, created_at: Date) {
        this.id = id;
        this.restaurant_id = restaurant_id;
        this.description = description;
        this.view_order_priority = view_order_priority;
        this.item_name = item_name;
        this.item_short_name = item_short_name;
        this.dietary_comments = dietary_comments;
        this.price = price;
        this.discounted_price = discounted_price;
        this.available = available;
        this.item_group_id = item_group_id;
        this.variations = variations;
        this.updated_at = updated_at;
        this.created_at = created_at;
    }
}



export class MenuGroup {
    id: number;
    group_name: string;
    description: string;
    view_order_priority: number;
    updated_at: Date;
    created_at: Date;
    items: Array<MenuItem>;

    constructor(
        id: number, group_name: string, description: string,
        view_order_priority: number, updated_at: Date, created_at: Date,
        items: Array<MenuItem>) {
        this.id = id;
        this.group_name = group_name;
        this.description = description;
        this.view_order_priority = view_order_priority;
        this.updated_at = updated_at;
        this.created_at = created_at;
        this.items = items;
    }
}

// ----------------------------------------
// DELIVERY COLLECTION OPEN HOURS
// ----------------------------------------

export class RestaurantCollectionSlot {
    status: string;
    day: number;
    start: string;
    end: string;


    constructor(status: string, day: number, start: string, end: string) {
        this.status = status;
        this.day = day;
        this.start = start;
        this.end = end;
    }
}

export class RestaurantCollection {
    enabled: boolean;
    status: string;
    interval: string;
    interval_in_minutes: number;
    interval_max_orders: number;
    slots: Array<RestaurantCollectionSlot>;

    constructor(
        enabled: boolean, status: string, interval: string,
        interval_in_minutes: number, interval_max_orders: number,
        slots: Array<RestaurantCollectionSlot>) {
        this.enabled = enabled;
        this.status = status;
        this.interval = interval;
        this.interval_in_minutes = interval_in_minutes;
        this.interval_max_orders = interval_max_orders;
        this.slots = slots;
    }
}



export class RestaurantDeliveryHour {
    status: string;
    day: number;
    delivery_start: string;
    delivery_end: string;


    constructor(status: string, day: number, delivery_start: string, delivery_end: string) {
        this.status = status;
        this.day = day;
        this.delivery_start = delivery_start;
        this.delivery_end = delivery_end;
    }
}

export class RestaurantDelivery {
    enabled: boolean;
    status: string;
    max_distance: number;
    min_delivery_charge: number;
    min_delivery_charge_miles: number;
    add_delivery_per_mile: number;
    min_order_total: number;
    postcode_exceptions: string;
    delivery_hours: Array<RestaurantDeliveryHour>


    constructor(enabled: boolean, status: string, max_distance: number, min_delivery_charge: number, min_delivery_charge_miles: number, add_delivery_per_mile: number, min_order_total: number, postcode_exceptions: string, delivery_hours: Array<RestaurantDeliveryHour>) {
        this.enabled = enabled;
        this.status = status;
        this.max_distance = max_distance;
        this.min_delivery_charge = min_delivery_charge;
        this.min_delivery_charge_miles = min_delivery_charge_miles;
        this.add_delivery_per_mile = add_delivery_per_mile;
        this.min_order_total = min_order_total;
        this.postcode_exceptions = postcode_exceptions;
        this.delivery_hours = delivery_hours;
    }
}

export class RestaurantProps {
    g_place_data: any;
    g_place_data_last_updated: Date;
    google_places_id: string;
    id: string;
}


export class RestaurantGooglePlaceData {
    place_data: any;
    place_id: string;
    updated_at: Date;
    restaurant_id: string;
}

export class OpenHour {
    restaurant_id: string;
    day: string;
    open: string;
    close: string;
    status: string;
    updated_at: Date;
    created_at: Date;


    constructor(restaurant_id: string, day: string, open: string, close: string, status: string, updated_at: Date, created_at: Date) {
        this.restaurant_id = restaurant_id;
        this.day = day;
        this.open = open;
        this.close = close;
        this.status = status;
        this.updated_at = updated_at;
        this.created_at = created_at;
    }
}

export class Restaurant {
    id: string;
    title: string;
    description: string;
    status: string;
    cuisines: Array<Cuisine>;
    amenities: Array<Amenity>;
    halalness: Halalness;
    address: RestaurantAddress;
    contact: RestaurantContact;
    distance: string;
    image: string;
    props: RestaurantProps;
    reviews: Array<RestaurantReview>;
    review_rating: number;
    type: string;
    menu: Array<MenuGroup>;
    is_seller: boolean;
    delivery: RestaurantDelivery;
    collection: RestaurantCollection;
    place_data: RestaurantGooglePlaceData;
    open_hours: Array<OpenHour>;
    created_at: Date;
    updated_at: Date;

    constructor(
        id: string, title: string, description: string, status: string,
        cuisines: Array<Cuisine>, amenities: Array<Amenity>,
        halalness: Halalness, address: RestaurantAddress,
        contact: RestaurantContact, distance: string, image: string,
        props: RestaurantProps, reviews: Array<RestaurantReview>, review_rating: number,
        type: string, menu: Array<MenuGroup>, is_seller: boolean,
        delivery: RestaurantDelivery, collection: RestaurantCollection,
        place_data: RestaurantGooglePlaceData, open_hours: Array<OpenHour>,
        created_at: Date, updated_at: Date) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.status = status;
        this.cuisines = cuisines;
        this.amenities = amenities;
        this.halalness = halalness;
        this.address = address;
        this.contact = contact;
        this.distance = distance;
        this.image = image;
        this.props = props;
        this.reviews = reviews;
        this.review_rating = review_rating;
        this.type = type;
        this.menu = menu;
        this.is_seller = is_seller;
        this.delivery = delivery;
        this.collection = collection;
        this.place_data = place_data;
        this.open_hours = open_hours;
        this.created_at = created_at;
        this.updated_at = updated_at;
    }
}

// ----------------------------------------
// ORDERS/BASKET START
// ----------------------------------------

export class BasketRequestItem {
    menu_item_id: string;
    quantity: number;

    constructor(menu_item_id: string, quantity: number) {
        this.menu_item_id = menu_item_id;
        this.quantity = quantity;
    }
}

export class BasketRequestCollection {
    estimated_time: string;

    constructor(estimated_time: string) {
        this.estimated_time = estimated_time;
    }
}

export class BasketRequestDelivery {

    estimated_time: string;
    address_street: string;
    address_postcode: string;
    address_town: string;

    constructor(
        estimated_time: string, address_street: string,
        address_postcode: string, address_town: string) {
        this.estimated_time = estimated_time;
        this.address_street = address_street;
        this.address_postcode = address_postcode;
        this.address_town = address_town;
    }
}

export class BasketValidationRequest {
    date : Date;
    restaurant_id : string;
    order_type: string;
    items: Array<BasketRequestItem>;
    collection: BasketRequestCollection;
    delivery: BasketRequestDelivery;
    comments: string;

    constructor(
        date: Date, restaurant_id: string, order_type: string,
        items: Array<BasketRequestItem>, collection: BasketRequestCollection,
        delivery: BasketRequestDelivery, comments: string) {
        this.date = date;
        this.restaurant_id = restaurant_id;
        this.order_type = order_type;
        this.items = items;
        this.collection = collection;
        this.delivery = delivery;
        this.comments = comments;
    }
}


export class BasketItem {
    item: MenuItem;
    variations: Array<MenuItemVariation>;
    quantity: number;
    price: number;

    constructor(
        item: MenuItem, variations: Array<MenuItemVariation>, quantity: number,
        price: number) {
        this.item = item;
        this.variations = variations != null ? variations.map(variation => new MenuItemVariation(
                variation?.id,
                variation?.item_id,
                variation?.variation_name,
                variation?.description,
                variation?.option_min,
                variation?.option_max,
                variation?.updated_at,
                variation?.created_at,
                variation?.options,
            )) : []
        this.quantity = quantity;
        this.price = price;
    }
}

export class BasketValidationResponse {
    items: Array<BasketItem>;
    has_order_changed: boolean;
    subtotal: number;
    service_charge : number;
    order_total: number;
    error: string;

    constructor(
        items: Array<BasketItem>, has_order_changed: boolean, subtotal: number,
        service_charge: number, order_total: number, error: string) {
        this.items = items;
        this.has_order_changed = has_order_changed;
        this.subtotal = subtotal;
        this.service_charge = service_charge;
        this.order_total = order_total;
        this.error = error;
    }
}

export class BasketCheckoutRequest {
    restaurant_id: string;
    items: Array<BasketItem>;
    order_time: string;
    order_type: string;
    order_id: string;
    delivery_address_street: string;
    delivery_address_postcode: string;
    delivery_address_town: string;
    comments: string;

    constructor(
        restaurant_id: string, items: Array<BasketItem>, order_time: string,
        order_type: string, order_id: string, delivery_address_street: string,
        delivery_address_postcode: string, delivery_address_town: string,
        comments: string) {
        this.restaurant_id = restaurant_id;
        this.items = items;
        this.order_time = order_time;
        this.order_type = order_type;
        this.order_id = order_id;
        this.delivery_address_street = delivery_address_street;
        this.delivery_address_postcode = delivery_address_postcode;
        this.delivery_address_town = delivery_address_town;
        this.comments = comments;
    }
}

export class BasketCheckoutResponse {
    date : Date;
    order_available_date_time_slots: Array<{
        date: Date,
        time_slots: Array<Date>
    }>
    restaurant_id : string;
    items: Array<BasketItem>;
    delivery_fee : number;
    collection: {
        estimated_time: string,
    }
    delivery: {
        estimated_time: string;
        address_street: string;
        address_postcode: string;
        address_town: string;
    }
    comments: string;
    has_order_changed: boolean;
    subtotal: number;
    service_charge : number;
    order_total: number;

    constructor(
        date: Date, restaurant_id: string,
        items: Array<BasketItem>,
        delivery_fee: number, collection: { estimated_time: string },
        delivery: { estimated_time: string, address_street: string, address_postcode: string, address_town: string },
        comments: string, has_order_changed: boolean, subtotal: number,
        service_charge: number, order_total: number) {
        this.date = date;
        this.restaurant_id = restaurant_id;
        this.items = items;
        this.delivery_fee = delivery_fee;
        this.collection = collection;
        this.delivery = delivery;
        this.comments = comments;
        this.has_order_changed = has_order_changed;
        this.subtotal = subtotal;
        this.service_charge = service_charge;
        this.order_total = order_total;
    }
}


export class OrderItemOption {
    id: string;
    order_item_id: string;
    order_id: string;
    menu_item_id : string;
    option_id : string;
    option_name: string;
    price_change: number;
    item: MenuItem;
    variation_id: string;
    comments: string;

    constructor(
        id: string, order_item_id: string, order_id: string,
        menu_item_id: string, option_id: string, option_name: string,
        price_change: number, item: MenuItem, variation_id: string,
        comments: string) {
        this.id = id;
        this.order_item_id = order_item_id;
        this.order_id = order_id;
        this.menu_item_id = menu_item_id;
        this.option_id = option_id;
        this.option_name = option_name;
        this.price_change = price_change;
        this.item = item;
        this.variation_id = variation_id;
        this.comments = comments;
    }
}

export class OrderItem {

    id: string;
    order_id: string;
    menu_item_id: string;
    restaurant_id: string;
    menu_item_name: string;
    price: number;
    rating: string;
    quantity: number;
    comments: string;
    options: Array<OrderItemOption>;
    item = MenuItem;

    constructor(
        id: string, order_id: string, menu_item_id: string,
        restaurant_id: string, menu_item_name: string, price: number,
        rating: string, quantity: number, comments: string,
        options: Array<OrderItemOption>, item: MenuItem) {
        this.id = id;
        this.order_id = order_id;
        this.menu_item_id = menu_item_id;
        this.restaurant_id = restaurant_id;
        this.menu_item_name = menu_item_name;
        this.price = price;
        this.rating = rating;
        this.quantity = quantity;
        this.comments = comments;
        this.options = options;
        this.item = item;
    }
}


export class Order {
    id: number;
    restaurant_id: string;
    reference: string;
    status: string;
    order_total: number;
    subtotal: number;
    service_charge: number;
    comments: string;
    eta:Date;
    order_date:Date;
    order_type: string;
    delivery_address: string;
    updated_at: Date;
    created_at : Date
    items: Array<OrderItem>;
    user: UserAccount;
    restaurant: Restaurant;

    constructor(
        id: number, restaurant_id: string, reference: string, status: string, order_total: number,
        subtotal: number, service_charge: number, comments: string, eta: Date,
        order_date: Date, order_type: string, delivery_address: string,
        updated_at: Date, created_at: Date, items: Array<OrderItem>,
        user: UserAccount, restaurant: Restaurant) {
        this.id = id;
        this.restaurant_id = restaurant_id;
        this.reference = reference;
        this.status = status;
        this.order_total = order_total;
        this.subtotal = subtotal;
        this.service_charge = service_charge;
        this.comments = comments;
        this.eta = eta;
        this.order_date = order_date;
        this.order_type = order_type;
        this.delivery_address = delivery_address;
        this.updated_at = updated_at;
        this.created_at = created_at;
        this.items = items;
        this.user = user;
        this.restaurant = restaurant;
    }
}


export class CheckoutResponse {
    order_data: Order;
    client_secret: string;
    connected_account: string;
    is_existing_order: boolean;
    has_order_changed: boolean;
    available_date_time_slots: Array<CheckoutDateTimeSlots>

    constructor(
        order_data: Order, client_secret: string, connected_account: string,
        is_existing_order: boolean, has_order_changed: boolean,
        available_date_time_slots: Array<CheckoutDateTimeSlots>) {
        this.order_data = order_data;
        this.client_secret = client_secret;
        this.connected_account = connected_account;
        this.is_existing_order = is_existing_order;
        this.has_order_changed = has_order_changed;
        this.available_date_time_slots = available_date_time_slots;
    }
}

export class CheckoutDateTimeSlots {
    date: Date;
    time_slots: Array<string>;

    constructor(date: Date, time_slots: Array<string>) {
        this.date = date;
        this.time_slots = time_slots;
    }
}

// ----------------------------------------
// ORDERS/BASKET END
// ----------------------------------------

export class RestaurantList {
    id: string;
    name: string;
    slug: string;
    user: UserAccountPublic;
    restaurants: Array<Restaurant>;
    createdOn: Date;
    lastUpdated: Date;

    constructor(
        id: string, name: string, slug: string, user: UserAccountPublic,
        restaurants: Array<Restaurant>, createdOn: Date, lastUpdated: Date) {
        this.id = id;
        this.name = name;
        this.slug = slug;
        this.user = user;
        this.restaurants = restaurants;
        this.createdOn = createdOn;
        this.lastUpdated = lastUpdated;
    }

    getUrl() {
        return `Checkout my favourite Halal food spots on Halal Munch!\n${window.location.origin}/${this.user.username}/${this.slug}`
    }
}
