import * as types from './actionTypes'

const DEFAULT_LAT = 51.5074;
const DEFAULT_LNG = 0.1278;

const INITIAL_STATE = {
  latitude: null,
  longitude: null,
  address: null,
  error: null,
  isLoading: false,
};

const locationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.FETCH_LOCATION_REQUEST:
      return {
        isLoading: true,
      };
    case types.FETCH_LOCATION_SUCCESS:
      return {
        latitude: action.payload.latitude,
        longitude: action.payload.longitude,
        isLoading: false,
        error: null,
      };
    case types.FETCH_LOCATION_FAILURE:
      return {
        ...state,
        latitude: DEFAULT_LAT,
        longitude: DEFAULT_LNG,
        error: action.payload,
        isLoading: false,
      };
    case types.SET_LOCATION_ADDRESS:
      return {
        ...state,
        address: action.payload.address,
        latitude: action.payload.latitude,
        longitude: action.payload.longitude,
      };
    case types.SET_LOCATION_LAT_LNG:
      return {
        ...state,
        latitude: action.payload.latitude,
        longitude: action.payload.longitude,
        isLoading: false,
        error: null,
      };
    default:
      return state;
  }
};

export const getLatitude = state => state.location.latitude;
export const getLongitude = state => state.location.longitude;
export const getLocationError = state => state.location.error;

export default locationReducer;
